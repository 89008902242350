<template>
    <div class="day-search-history">
        <table>
            <thead>
                <tr>
                    <th>
                        Request Time
                    </th>
                    <th>
                        Username
                    </th>
                    <th>
                        User Registration Date
                    </th>
                    <th>
                        Lifetime Requests
                    </th>
                    <th>
                        Average Days Between Requests
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(entry, index) in sortedEntries">
                    <td>
                        {{ formatTimeForDisplay(entry.createdAt) }}
                    </td>
                    <td>
                        <a target="_blank" :href="'https://myanimelist.net/profile/' + entry.username">
                            {{ entry.username }}
                        </a>
                    </td>
                    <td>
                        {{ formatDateForDisplay(entry.registeredAt) }}
                    </td>
                    <td>
                        {{ formatTableValue(entry.numberOfLifetimeRequests) }}
                    </td>
                    <td>
                        {{ formatTableValue(entry.averageDaysBetweenRequests) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script type="text/babel">

export default {
    props: ['date', 'searchHistoryEntries'],
    computed: {
        sortedEntries () {
            return this.searchHistoryEntries.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        },
    },
    methods: {
        formatDateForDisplay (dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        formatTimeForDisplay (date) {
            return new Date(date).toLocaleTimeString();
        },
        formatTableValue (value) {
            if (value === null) return '---';

            return value;
        },
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    table {
        border-collapse: collapse;
        width: 100%;

        th {
            border-bottom: 1px solid gray;
            text-align: left;
            padding-right: 20px;
        }

        tbody {
            tr:nth-child(2n+1) {
                background-color: #F6F6F6;
            }

            td {
                padding: 2px 20px 2px 0px;
            }
        }

        a {
            text-decoration: none;
            color: #42b983;

            &:visited {
                color: #35495e;
            }
        }
    }
</style>
