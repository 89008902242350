<template>
    <div class="rolling-average">
        <div class="title">
            <div>
                {{ title }}
            </div>
            <div class="avg-text">
                30-Day Average
            </div>
        </div>
        <div class="big-text">
            {{ searchEntriesRollingAverage }}
        </div>
    </div>
</template>

<script>
import map from 'lodash/map';
import mean from 'lodash/mean';

export default {
    props: ['searchEntriesByDay', 'title'],
    computed: {
        searchEntriesRollingAverage () {
            return this.takeMeanOfRequestsPerDay(this.searchEntriesByDay);
        },
    },
    methods: {
        takeMeanOfRequestsPerDay (requestsPerDay) {
            const requests = map(requestsPerDay, entrySet => entrySet.length);

            return mean(requests).toFixed(1);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.rolling-average {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #AAA;
    padding: 20px;

    .title {
        font-size: 16px;
        text-align: center;
        padding-bottom: 5px;
    }

    .avg-text {
        font-size: 14px;
    }

    .big-text {
        font-size: 30px;
        font-weight: bold;
    }
}
</style>
