// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VueLazyload from 'vue-lazyload';
import VTooltip from 'v-tooltip';
import VueProgressBar from 'vue-progressbar';
import 'vue-material-button/dist/style.css';
import 'element-ui/lib/theme-chalk/index.css';

import store from './vuex';
import App from './app';
import ProgressBarInterceptor from './helpers/progress-bar-interceptors';

import router from './router';

Vue.config.productionTip = false;
sync(store, router);

Vue.use(VueLazyload, {
    attempt: 1,
    adapter: {
        error ({ el }) {
            el.dispatchEvent(new Event('error'));
        },
    },
});
Vue.use(VTooltip);
Vue.use(VueProgressBar, {
    color: '',
    failedColor: '',
    thickness: '',
    transition: {
        speed: '0.5s',
        opacity: '0.3s',
        termination: 1000,
    },
    autoRevert: true,
});

/* eslint-disable no-new */
window.vueRouter = router;
new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    created () {
        ProgressBarInterceptor(this.$Progress);
    },
    components: { App, VueProgressBar },
});
