import axios from 'axios';
import Vue from 'vue';
import { unicodeFilter } from '@/helpers/media-helpers';

const initialState = {
    userProfile: {
        loading: false,
        error: null,
        data: null,
    },
    userListSync: {
        loading: false,
        error: null,
    },
    compatibleUsers: {
        loading: false,
        error: null,
        list: [],
    },
    animeList: {
        loading: false,
        error: null,
        list: [],
    },
    mangaList: {
        loading: false,
        error: null,
        list: [],
    },
};

const getters = {

};

const actions = {
    async syncUserLists ({ commit, dispatch }, { username }) {
        commit('syncingUserList', true);

        let result;

        try {
            result = await axios.post(`/api/sync/${username}`);
        } catch (error) {
            commit('syncingUserList', false);
            const errorMessage = error.response.data && error.response.data.message ? error.response.data.message : 'Ah geez, you shouldn\'t be seeing this, something went really wrong...';
            return commit('failedSyncingUserList', { error: errorMessage });
        }

        const { animeList, mangaList } = result.data;

        commit('syncingUserList', false);
        commit('recievedUserList', { list: animeList, listType: 'anime' });
        commit('recievedUserList', { list: mangaList, listType: 'manga' });
        await dispatch('loadUserProfile', { username });
        await dispatch('loadCompatibleUsers', { username });
    },
    async loadUserProfile ({ commit }, { username }) {
        commit('loadingUserProfile', true);

        try {
            const response = await axios.get(`/api/user/${username}`);
            commit('loadingUserProfile', false);
            commit('recievedUserProfile', response.data);
        } catch (error) {
            commit('resetUser');
            commit('loadingUserProfile', false);
            commit('failedUserProfile', error);
        }
    },

    async loadCompatibleUsers ({ commit }, { username }) {
        commit('loadingCompatibleUsers', true);
        let response;
        try {
            response = await axios.get(`/api/metrics/${username}/compatible-users`);
        } catch (error) {
            commit('loadingCompatibleUsers', false);
            return commit('failedCompatibleUsers', error);
        }

        commit('loadingCompatibleUsers', false);
        commit('recievedCompatibleUsers', response.data);
    },

    async loadUserList ({ commit }, { username, type }) {
        commit('loadingUserList', { listType: type, isLoading: true });

        let response;
        try {
            response = await axios.get(`/api/list/${username}/${type}`);
        } catch (error) {
            commit('loadingUserList', { listType: type, isLoading: false });
            return commit('failedUserList', { listType: type, error });
        }

        commit('loadingUserList', { listType: type, isLoading: false });
        commit('recievedUserList', { list: response.data, listType: type });
    },

};

const mutations = {
    syncingUserList (state, isLoading) {
        Vue.set(state.userListSync, 'loading', isLoading);
    },
    failedSyncingUserList (state, { error }) {
        Vue.set(state.userListSync, 'error', error);
    },
    loadingUserProfile (state, isLoading) {
        Vue.set(state.userProfile, 'loading', isLoading);
    },
    recievedUserProfile (state, userData) {
        state.userProfile = {
            loading: false,
            error: false,
            data: userData,
        };
        state.userListSync = {
            loading: false,
            error: null,
        };
    },
    failedUserProfile (state, error) {
        Vue.set(state.userProfile, 'error', error);
    },
    resetUser (state) {
        state.userProfile = {
            loading: false,
            error: null,
            data: null,
        };
        state.userListSync = {
            loading: false,
            error: null,
        };
        state.compatibleUsers = {
            loading: false,
            error: null,
            list: [],
        };
        state.animeList = {
            loading: false,
            error: null,
            list: [],
        };
        state.mangaList = {
            loading: false,
            error: null,
            list: [],
        };
    },
    loadingCompatibleUsers (state, isLoading) {
        Vue.set(state.compatibleUsers, 'loading', isLoading);
    },
    recievedCompatibleUsers (state, compatibleUsers) {
        Vue.set(state.compatibleUsers, 'list', compatibleUsers);
    },
    failedCompatibleUsers (state, error) {
        Vue.set(state.compatibleUsers, 'error', error);
    },

    loadingUserList (state, { isLoading, listType }) {
        if (listType === 'anime') {
            Vue.set(state.animeList, 'loading', isLoading);
        } else if (listType === 'manga') {
            Vue.set(state.mangaList, 'loading', isLoading);
        }
    },
    recievedUserList (state, { list, listType }) {
        let userList = list.sort(item => -1 * item.rawScore);

        userList.forEach(media => {
            media.title = unicodeFilter(media.title);
        });

        userList = Object.freeze(userList);

        if (listType === 'anime') {
            Vue.set(state.animeList, 'list', userList);
        } else if (listType === 'manga') {
            Vue.set(state.mangaList, 'list', userList);
        }
    },
    failedUserList (state, { error, listType }) {
        if (listType === 'anime') {
            Vue.set(state.animeList, 'error', error);
        } else if (listType === 'manga') {
            Vue.set(state.mangaList, 'error', error);
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
