<template>
    <div id="last-month-search-history">
        <div class="column">
            <search-history-chart
                :search-history-entries="searchEntriesByDay"
                :quick-search-history-entries="quickSearchEntriesByDay">
            </search-history-chart>
            <div class="search-history-list">
                <div class="single-day-totals" v-for="(date, index) in sortedDates" :key="index">
                    <h3>
                        {{ formatDateForDisplay(date) }}
                        <span class="day-totals">
                            {{ totalEntriesLabel(searchEntriesByDay[date], 'Visitor', 's') }},
                            {{ totalEntriesLabel(quickSearchEntriesByDay[date], 'Quick Search', 'es') }}
                        </span>
                    </h3>
                    <day-search-history
                        v-if="searchEntriesByDay[date]"
                        :search-history-entries="searchEntriesByDay[date]"
                        :date="date">
                    </day-search-history>
                </div>
            </div>
        </div>

        <div class="rolling-30-day-totals">
            <rolling-average
                :title="'Search Entries'"
                :search-entries-by-day="searchEntriesByDay">
            </rolling-average>
            <rolling-average
                :title="'Quick Search Entries'"
                :search-entries-by-day="quickSearchEntriesByDay">
            </rolling-average>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import mean from 'lodash/mean';
import DaySearchHistory from './components/day-search-history';
import SearchHistoryChart from './components/search-history-chart';
import RollingAverage from './components/rolling-average';

export default {
    components: {
        DaySearchHistory,
        SearchHistoryChart,
        RollingAverage,
    },
    created () {
        this.loadLastMonthSearchHistory();
        this.loadQuickSearchHistory();
    },
    computed: {
        ...mapState({
            lastMonthHistory: state => state.lastMonthSearchHistory.lastMonthHistory,
            quickSearchHistoryEntries: state => state.quickSearchHistory.quickSearchHistoryEntries,
        }),
        searchEntriesByDay () {
            return this.groupEntriesByDay(this.lastMonthHistory);
        },
        searchEntriesRollingAverage () {
            return this.takeMeanOfRequestsPerDay(this.searchEntriesByDay);
        },
        quickSearchEntriesByDay () {
            return this.groupEntriesByDay(this.quickSearchHistoryEntries);
        },
        quickSearchEntriesRollingAverage () {
            return this.takeMeanOfRequestsPerDay(this.quickSearchEntriesByDay);
        },
        sortedDates () {
            const searchHistoryDays = this.buildOrderedListOfDays(this.searchEntriesByDay);
            const quickSearchHistoryDays = this.buildOrderedListOfDays(this.quickSearchEntriesByDay);
            const allDays = uniq(searchHistoryDays.concat(quickSearchHistoryDays));
            return allDays;
        },
    },
    methods: {
        ...mapActions('lastMonthSearchHistory/', [
            'loadLastMonthSearchHistory',
        ]),
        ...mapActions('quickSearchHistory/', [
            'loadQuickSearchHistory',
        ]),
        totalEntriesLabel (searchEntries, type, plural) {
            if (!searchEntries) return `0 ${type}${plural}`;

            let text = `${searchEntries.length} ${type}`;

            if (searchEntries.length !== 1) {
                text += plural;
            }

            return text;
        },
        formatDateForDisplay (dateInMs) {
            return new Date(Number(dateInMs)).toDateString();
        },
        groupEntriesByDay (entries) {
            if (!entries) return null;

            const groupedStates = {};
            entries.forEach((entry) => {
                const requestedAt = new Date(entry.createdAt);
                const requestedAtKey = new Date(requestedAt.getFullYear(), requestedAt.getMonth(), requestedAt.getDate()).getTime();
                if (!groupedStates[requestedAtKey]) {
                    groupedStates[requestedAtKey] = [];
                }

                groupedStates[requestedAtKey].push(entry);
            });

            return groupedStates;
        },
        buildOrderedListOfDays (entriesByDay) {
            return Object.keys(entriesByDay).sort((a, b) => b - a);
        },
        takeMeanOfRequestsPerDay (requestsPerDay) {
            const requests = map(requestsPerDay, entrySet => entrySet.length);

            return mean(requests).toFixed(1);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#last-month-search-history {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .column {
        display: flex;
        flex-direction: column;
        width: 60%;
        min-width: 1000px;
    }

    .rolling-30-day-totals {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        .rolling-average {
            flex-grow: 0;
            flex-shrink: 0;
            margin: 20px;
        }
    }

    .search-history-list {
        box-sizing: border-box;
        min-height: 400px;
        width: calc(100% - 20px);
        overflow: scroll;
        border: 1px solid #CCC;
        padding: 10px;

        .single-day-totals {
            border-bottom: 1px solid #CCC;
            margin-bottom: 20px;
            h3:first-child {
                margin-top: 0px;
            }

            .day-totals {
                font-weight: normal;
                font-size: 14px;
                margin-left: 10px;
            }

            .day-search-history, .day-quick-search-history {
                margin-bottom: 15px;
            }
        }
    }

    #search-history-chart {
        box-sizing: border-box;
        position: relative;
        margin-bottom: 30px;
    }
}
</style>
