import axios from 'axios';

const initialState = {
    mediaChoices: [],
    mediaChoiceType: null,
    algorithm: 'popular',
    loading: false,
    recommendations: null,
    seasonalRecs: null,
    mediaType: 'anime',
    error: '',
};

const mutations = {
    addChoice (state, { mediaChoice }) {
        state.mediaChoices = state.mediaChoices.concat(mediaChoice);
    },
    removeChoice (state, { mediaChoice }) {
        state.mediaChoices = state.mediaChoices.filter(choice => choice.id !== mediaChoice.id);
    },
    resetMediaChoices (state) {
        state.mediaChoices = [];
    },
    setMediaChoiceType (state, { mediaChoiceType }) {
        state.mediaChoiceType = mediaChoiceType;
    },
    setAlgorithm (state, { algorithm }) {
        state.algorithm = algorithm;
    },
    setLoading (state, { loading }) {
        state.loading = loading;
    },
    setMediaType (state, { mediaType }) {
        state.mediaType = mediaType;
    },
    setRecommendations (state, { recommendations }) {
        state.recommendations = recommendations;
    },
    setSeasonalRecs (state, { seasonalRecs }) {
        state.seasonalRecs = Object.freeze(seasonalRecs);
    },
    setError (state, { error }) {
        state.error = error;
    },
};

const actions = {
    addMediaChoice ({ commit, state }, { mediaChoice }) {
        if (!state.mediaChoices.length) {
            commit('setMediaChoiceType', { mediaChoiceType: mediaChoice.type });
        }
        commit('addChoice', { mediaChoice });
    },
    async removeMediaChoice ({ commit, state }, { mediaChoice }) {
        await commit('removeMediaChoice', { mediaChoice });
        if (!state.mediaChoices.length) {
            commit('setMediaChoiceType', { mediaChoiceType: 'all' });
        }
    },
    clearMediaChoices ({ commit, dispatch }) {
        dispatch('resetRecommendations');
        commit('resetMediaChoices');
        commit('setMediaChoiceType', { mediaChoiceType: 'all' });
    },
    async getSeasonalRecommendations ({ commit }) {
        const response = await axios.post(`/api/current-season-recs`);
        commit('setSeasonalRecs', { seasonalRecs: response.data.seasonal });
    },
    async getRecommendationsForUser ({ commit, rootState, state }, { username, type }) {
        commit('setLoading', { loading: true });
        let response;
        try {
            const postBody = {
                include: rootState.genre.includeGenres,
                exclude: rootState.genre.excludeGenres,
                weightType: state.algorithm,
            };

            if (rootState.filters.seasonFilterEnabled && type === 'anime') {
                postBody.seasonYears = rootState.filters.yearsToFilter;
            }

            response = await axios.post(`/api/recommendations/${username}/${type}`, postBody);
        } catch (err) {
            commit('setLoading', { loading: false });
            const errorMessage = err.response.data && err.response.data.message ? err.response.data.message : 'Ah geez, you shouldn\'t be seeing this, something went really wrong...';
            commit('setError', { error: errorMessage });
            return;
        }

        commit('setError', { error: null });
        commit('setRecommendations', { recommendations: response.data });
    },
    async getQuickRecommendations ({ commit, rootState }, { mediaIds, type }) {
        commit('setLoading', { loading: true });
        let response;
        try {
            const postBody = {
                include: rootState.genre.includeGenres,
                exclude: rootState.genre.excludeGenres,
                mediaIds,
                type,
            };

            if (rootState.filters.seasonFilterEnabled && type === 'anime') {
                postBody.seasonYears = rootState.filters.yearsToFilter;
            }

            response = await axios.post('/api/quick-recommendations', postBody);
        } catch (err) {
            commit('setLoading', { loading: false });
            const errorMessage = err.response.data && err.response.data.message ? err.response.data.message : 'Ah geez, you shouldn\'t be seeing this, something went really wrong...';
            commit('setError', { error: errorMessage });
            return;
        }

        commit('setError', { error: null });
        commit('setRecommendations', {
            recommendations: {
                quickRecommendations: response.data,
            },
        });
    },
    resetRecommendations ({ commit }) {
        commit('setError', { error: null });
        commit('setRecommendations', { recommendations: null });
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
