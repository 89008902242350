<template>
    <div class="omnibar">
        <div class="input-container">
            <input class="search-box"
                v-model="searchTerm"
                @input="debounceSearch"
                @focus="focusInput"
                @blur="blurInput"
                @keydown="catchKeypress"
                :disabled="isDisabled || hasMaxEntries"
                autofocus
                :placeholder="placeholderText" />
        </div>
        <div class="search-results" v-if="showResults">
            <div class="type-container" v-for="(category, type) in formattedResults" :key="type">
                <div class="type">
                    <span class="type-title">{{type}}</span>
                </div>
                <div class="item" v-for="item in category"
                    :key="item.id"
                    @click="selectMedia(item)"
                    :class="{ highlighted: item.highlighted }">
                    <span v-if="item.title" class="item-name">
                        {{ unicodeFormat(item.title) }}
                        <span v-if="item.alternateTitle">({{ unicodeFormat(item.alternateTitle) }})</span>
                    </span>
                    <span v-if="item.username" class="item-name">
                        {{ item.searchText || item.username }}
                    </span>
                </div>
            </div>
            <div class="type-container" v-if="!flattenedResults.length && !error">
                <div class="type">
                    No results were found for "{{searchTerm}}"
                </div>
            </div>
            <div class="type-container error-bg bold" v-if="error">
                <div class="type">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import flatten from 'lodash/flatten';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import { getMediaById } from '@/helpers/media-helpers';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Omnibar',
    props: ['isDisabled'],
    data () {
        return {
            searchTerm: '',
            error: null,
            inputHasFocus: false,
            highlightIndex: null,
        };
    },
    async created () {
        const { query } = this.$route;

        if (query.type === 'user' && query.username) {
            await this.selectMedia({ type: 'user', username: query.username });
        } else if (query.mediaIds) {
            const media = await getMediaById(query.mediaIds);
            media.forEach((mediaChoice) => {
                this.addMediaChoice({ mediaChoice });
            });
        }
    },
    computed: {
        ...mapState({
            results: state => state.search.results,
            mediaChoices: state => state.recommendations.mediaChoices,
            mediaChoiceType: state => state.recommendations.mediaChoiceType,
            userProfile: state => state.user.userProfile,
            userListSync: state => state.user.userListSync,
            user: state => state.user.userProfile.data,
        }),
        placeholderText () {
            if (this.isDisabled) {
                return '';
            }

            return 'enter an anime, manga, or MyAnimeList username...';
        },
        showResults () {
            return !!this.results && this.inputHasFocus && this.searchTerm.length >= 3;
        },
        debounceSearch () {
            return debounce(() => {
                this.searchForTerm(this.searchTerm);
            }, 300);
        },
        formattedResults () {
            if (!this.results) return null;
            const multipleResultSets = Object.keys(this.results).length > 1;
            const numberOfResultsPerType = multipleResultSets ? 4 : 10;
            const filteredResults = {};
            let indexPointer = 0;

            Object.keys(this.results).forEach((type) => {
                if (!this.results[type]?.length) return;

                filteredResults[type] = [];
                for (let i = 0; i < numberOfResultsPerType; i++) {
                    const itemToPush = this.results[type][i];

                    if (!itemToPush) return;

                    if (itemToPush.id) {
                        const alreadyChosen = this.mediaChoices.find(media => itemToPush.id === media.id);
                        if (alreadyChosen) continue;
                    }

                    itemToPush.highlighted = false;
                    if (indexPointer === this.highlightIndex) {
                        itemToPush.highlighted = true;
                    }

                    filteredResults[type].push(itemToPush);
                    indexPointer++;
                }
            });

            return filteredResults;
        },
        flattenedResults () {
            return flatten(Object.keys(this.formattedResults).map(type => this.formattedResults[type]));
        },
        hasMaxEntries () {
            return this.mediaChoices.length >= 8;
        },
    },
    methods: {
        ...mapActions('search', ['searchForMedia', 'clearSearchResults']),
        ...mapActions('user', ['loadUserProfile', 'loadUserList', 'syncUserLists']),
        ...mapActions('recommendations', ['addMediaChoice', 'clearMediaChoices']),
        unicodeFormat (string) {
            if(!string) return '';

            return string.replace(/\\u[\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef]/g,
                match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));
        },
        focusInput () {
            this.inputHasFocus = true;
        },
        blurInput () {
            window.setTimeout(() => {
                this.inputHasFocus = false;
            }, 200);
        },
        async selectMedia (mediaChoice) {
            if (this.mediaChoices.length >= 8) return;
            this.searchTerm = '';
            this.clearSearchResults();

            const type = mediaChoice.type;
            const query = { type };

            if (type === 'user') {
                this.clearMediaChoices();
                if (!this.user || mediaChoice.username !== this.user.username) {
                    await this.loadUserProfile({ username: mediaChoice.username });

                    if (this.userProfile.error) {
                        await this.attemptToSyncNewUser(mediaChoice.username);
                    }

                    if (this.userListSync.error) return;
                }

                query.username = mediaChoice.username;
                this.loadUserList({ username: mediaChoice.username, type: 'anime' });
                this.loadUserList({ username: mediaChoice.username, type: 'manga' });
            } else {
                let currentMediaIds = this.$route.query.mediaIds;
                if (!currentMediaIds) {
                    currentMediaIds = [];
                } else {
                    currentMediaIds = currentMediaIds.split(',');
                }

                currentMediaIds.push(mediaChoice.id);
                query.mediaIds = currentMediaIds.join(',');
            }

            this.addMediaChoice({ mediaChoice });

            const alreadyLookingAtRecommendations = this.$route.name === 'RecommendationsPage';
            const queryNeedsToUpdate = !isEqual(this.$route.query, query);

            if (alreadyLookingAtRecommendations && queryNeedsToUpdate) {
                this.$router.replace({ name: 'RecommendationsPage', query });
            } else if(!alreadyLookingAtRecommendations) {
                this.$router.push({ name: 'RecommendationsPage', query });
            }
        },
        async attemptToSyncNewUser (username) {
            await this.syncUserLists({ username });
        },
        catchKeypress (event) {
            if (!this.showResults) return;

            const { code } = event;

            if (code === 'Enter') {
                if (!isNumber(this.highlightIndex)) {
                    this.highlightIndex = 0;
                }

                this.selectMedia(this.flattenedResults[this.highlightIndex]);
                return;
            }

            if (code !== 'ArrowDown' && code !== 'ArrowUp') return;

            const indexIncrement = code === 'ArrowDown' ? 1 : -1;
            let nextIndex = this.highlightIndex;

            if (!isNumber(nextIndex)) {
                nextIndex = -1;
            }

            nextIndex += indexIncrement;

            if (nextIndex < 0) {
                nextIndex = this.flattenedResults.length - 1;
            }

            if (nextIndex > this.flattenedResults.length - 1) {
                nextIndex = 0;
            }

            this.highlightIndex = nextIndex;
        },
        async searchForTerm (searchTerm) {
            if (searchTerm && searchTerm.length >= 3) {
                await this.searchForMedia({ searchTerm, type: this.mediaChoiceType });
            }
        },
    },
};

</script>

<style scoped lang="scss">
@import "../mixins/all.scss";

.omnibar {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .input-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 30px;
        flex-shrink: 0;

        .search-box {
            background: none;
            border: none;
            border-bottom: 2px solid rgba($verdegris, 0.3);
            transition: border-bottom-color 100ms linear;
            color: #FFF;
            font-size: 20px;
            margin-right: 10px;
            padding-left: 20px;
            height: 41px;
            width: 100%;

            &:focus {
                outline: none;
                border-bottom-color: $verdegris;
            }

            &:placeholder-shown {
                color: $verdegris;
                opacity: 1;

                @include phone() {
                    font-size: 18px;
                };
            }

            &:disabled {
                cursor: not-allowed;
                border-bottom-color: rgba(#757575, 0.3);
            }
        }
    }

    .search-results {
        width: 100%;
        background-color: #fff;
        background-color: #d7dee2;
        z-index: 100;

        .type-container {
            border-bottom: 1px solid #c1ccd1;
            padding: 10px 0;

            .type {
                padding-left: 20px;

                .type-title {
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: bold;
                    padding-right: 20px;
                }
            }

            .item {
                cursor: pointer;
                padding-left: 30px;
                display: flex;
                align-items: center;
                height: 30px;
                padding-top: 2.5px;
                padding-bottom: 2.5px;

                &:hover, &.highlighted {
                    background-color: #E0F2FD;
                }
            }

            &.error-bg {
                border-bottom: none;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

</style>
