<template>
    <a class="condensed-media-entry"
        :class="{ 'no-image': noImage }"
        :href="`https://myanimelist.net/${mediaEntry.type}/${mediaEntry.MALid}`"
        target="_blank">
        <img v-lazy="mediaEntry.MALimageLink" class="entry-cover-image" @error="imageLoadingError()" v-if="!noImage">
        <div class="no-image-message" v-if="noImage">
            <div>
                Image
            </div>
            <div>
                Not Found.
            </div>
        </div>
        <div class="title">
            <div>
                {{ mediaEntry.title }}
            </div>
            <div class="alt-title" v-if="mediaEntry.alternateTitle">
                {{ mediaEntry.alternateTitle }}
            </div>
            <div class="subtext" v-if="titleSubtext">
                {{ titleSubtext }}
            </div>
        </div>
        <div class="user-rating" v-if="mediaEntry.rawScore">
            {{ mediaEntry.rawScore }}
        </div>
    </a>
</template>

<script>
export default {
    name: 'CondensedMediaEntry',
    props: ['mediaEntry', 'titleSubtext'],
    data () {
        return {
            noImage: false,
        };
    },
    methods: {
        imageLoadingError () {
            this.noImage = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../../mixins/all.scss";

.condensed-media-entry {
    width: 150px;
    height: 214px;
    display: block;
    position: relative;
    border: 1px solid transparentize($olive-black, 0.7);
    text-decoration: none;

    &:hover {
        .entry-cover-image {
            opacity: 1;
        }

        .title {
            display: none;
        }
    }

    &.no-image:hover {
        .title {
            background-color: $olive-black;
            display: block;
        }
    }

    .alt-title {
        font-size: 14px;
    }

    .entry-cover-image {
        opacity: 0.8;
        width: 100%;
        height: 100%;
    }

    .no-image-message {
        height: 100%;
        width: 100%;
        flex-direction: column;
        padding-top: 10px;
        font-size: 18px;
        background-color: transparentize($olive-black, 0.9);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .title {
        background-color: transparentize($olive-black, 0.3);
        box-sizing: border-box;
        color: $isabelline;
        font-size: 18px;
        font-weight: bold;
        left: 0px;
        min-height: 60px;
        overflow: hidden;
        padding: 5px;
        position: absolute;
        text-shadow: 0 0 10px $olive-black;
        top: 0px;
        width: 100%;
    }

    .subtext {
        font-size: 14px;
        padding-top: 10px;
    }

    .user-rating {
       color: $isabelline;
       position: absolute;
       right: 10px;
       bottom: 0px;
       font-size: 50px;
       text-shadow: 1px 1px 1px $olive-black;
       font-weight: bold;
   }
}

</style>
