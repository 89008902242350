<template>
    <div class="rec-filters">
        <div
            class="backdrop"
            @click="closeModal">
        </div>
        <div class="modal">
            <div v-if="mediaType === 'anime'" class="season-container">
                <h3>Season Filter</h3>
                <checkbox
                    class="season-checkbox"
                    v-model="localSeasonFilterEnabled"
                    color="#44bba4">
                    Only show {{ mediaType }} from the past
                <select
                    :value="yearsToFilter"
                    class="season-year-dropdown"
                    @input="updateYearsToFilter($event.target.value)">
                    <option value="1">Year</option>
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>
                </select>
                </checkbox>
            </div>
            <h3>Genre Filter</h3>
            <div class="genre-container">
                <material-button
                    class="genre-option"
                    v-for="genre in genreFilterOptions"
                    :class="getGenreFilterOptionClass(genre)"
                    @click="toggleGenreOption(genre)"
                    :disabled="!genre.genreCount && !genre.exclude"
                    :key="genre.name"
                    :raised="true"
                    :ripple="true"
                    :large="true">
                    <div class="title">
                        {{ genre.name }}
                    </div>
                    <div class="subtitle">
                        {{ genre.exclude ? 'excluded' : genre.genreCount }}
                    </div>
                </material-button>
            </div>
            <div class="button-bar">
                <material-button
                    class="reset-btn"
                    :disabled="!includeGenres.length && !excludeGenres.length"
                    :raised="true"
                    :ripple="true"
                    :large="true"
                    @click="resetGenreFilter">
                    Reset Filter
                </material-button>
                <material-button
                    class="update-btn"
                    :raised="true"
                    :ripple="true"
                    :large="true"
                    @click="closeModal">
                    Update Filter
                </material-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import MaterialButton from 'vue-material-button';
import Checkbox from 'vue-material-checkbox'

export default {
    name: 'RecGenreFilter',
    props: ['mediaType'],
    components: {
        MaterialButton,
        Checkbox,
    },
    data () {
        return {
            startingIncludeFilter: [],
            startingExcludeFilter: [],
            startingSeasonFilterEnabled: null,
            startingSeasonYears: null,
        }
    },
    async created () {
        if (!this.includeGenres.length && !this.excludeGenres.length) {
            await this.getGenresForType({ type: this.mediaType });
        } else {
            this.startingIncludeFilter = this.includeGenres;
            this.startingExcludeFilter = this.excludeGenres;
        }

        this.startingSeasonFilterEnabled = !!this.seasonFilterEnabled;
        this.startingSeasonYears = this.yearsToFilter;

        window.addEventListener('keydown', this.escapeListener);
    },
    beforeDestroy () {
        window.removeEventListener('keydown', this.escapeListener);
    },
    computed: {
        ...mapState({
            genreFilterOptions: state => state.genre.options,
            includeGenres: state => state.genre.includeGenres,
            excludeGenres: state => state.genre.excludeGenres,
            seasonFilterEnabled: state => state.filters.seasonFilterEnabled,
            yearsToFilter: state => state.filters.yearsToFilter,
        }),
        localSeasonFilterEnabled: {
            get () {
                return this.seasonFilterEnabled
            },
            set (newVal) {
                this.setSeasonFilterEnabled({ seasonFilterEnabled: newVal });
            }
        }
    },
    methods: {
        ...mapActions('genre', [
            'getGenresForType',
            'addExcludeGenre',
            'addIncludeGenre',
            'removeExcludeGenre',
            'removeIncludeGenre',
            'resetGenreFilter',
        ]),
        ...mapMutations('filters', [
            'setSeasonFilterEnabled',
            'setYearsToFilter',
        ]),
        toggleGenreOption (option) {
            const { id } = option;

            if (!option.include && !option.exclude) {
                option.include = true;
                return this.addIncludeGenre({ id });
            }

            if (option.include) {
                option.exclude = true;
                this.removeIncludeGenre({ id });
                return this.addExcludeGenre({ id });
            }

            if (option.exclude) {
                option.exclude = false;
                return this.removeExcludeGenre({ id });
            }
        },
        getGenreFilterOptionClass (option) {
            if (option.include) return 'include';
            if (option.exclude) return 'exclude';
            return '';
        },
        closeModal () {
            this.$emit('close');

            const newExcludeFilters = !isEqual(this.excludeGenres, this.startingExcludeFilter);
            const newIncludeFilters = !isEqual(this.includeGenres, this.startingIncludeFilter);
            const newSeasonFilterEnabled = !isEqual(this.startingSeasonFilterEnabled, this.seasonFilterEnabled);
            const newSeasonYears = !isEqual(this.startingSeasonYears, this.yearsToFilter);

            if (newIncludeFilters || newExcludeFilters || newSeasonFilterEnabled || newSeasonYears) {
                this.$emit('update-filter');
            }
        },
        updateSeasonFilterEnabled () {
            this.setSeasonFilterEnabled({ seasonFilterEnabled: !this.seasonFilterEnabled });
        },
        updateYearsToFilter (newYears) {
            this.setSeasonFilterEnabled({ seasonFilterEnabled: true });
            this.setYearsToFilter({ yearsToFilter: newYears });
        },
        escapeListener (event) {
            if(event.keyCode === 27) {
                this.closeModal();
            }
        }
    },
    watch: {
        mediaType (mediaType) {
            this.getGenresForType({ type: mediaType });
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

h3 {
    margin: 0px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    border-bottom: 1px solid darken($isabelline, 20);
}

.rec-filters {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    flex-direction: column;

    ::v-deep .genre-option.material-button__component {
        flex-shrink: 0;
        display: flex;

        .material-button {
            padding: 0px;
        }

        &.include {
            .material-button {
                background-color: lighten($verdegris, 30);
            }
        }

        &.exclude {
            .material-button {
                background-color: lighten($carmine-pink, 30);
            }
        }
    }
}

.season-container {
    margin-bottom: 20px;
}

.season-checkbox {
    margin: 0px;
    width: initial;

    ::v-deep .m-chckbox--label {
        padding-left: 10px;
    }
}

.season-year-dropdown {
    font-size: 18px;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: transparentize($isabelline, 0.1);
    z-index: 10;
    top: 0;
    left: 0;
}

.modal {
    width: 80%;
    padding: 5%;
    margin-top: 5%;
    z-index: 11;
    background-color: $isabelline;
    border: 1px solid transparentize($olive-black, 0.8);
    box-sizing: border-box;
}

.genre-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-gap: 10px;
    justify-content: space-between;

    &::after {
        content: "";
        flex: auto;
    }
}

.button-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;

    ::v-deep .material-button__component {
        height: 50px;
        width: calc(13.5% + 5px);
        min-width: 180px;

        ::v-deep .material-button {
            width: 100%;
            height: 100%;
        }
    }

    .update-btn {
        ::v-deep .material-button {
            background-color: $verdegris;
            border: 2px solid darken($verdegris, 10);
            font-weight: bold;
            color: $isabelline;
        }
    }

    .reset-btn {
        margin-right: 20px;

        ::v-deep .material-button {
            background-color: transparentize($isabelline, 0.3);
            border: 2px solid transparentize($olive-black, 0.3);
            color: $olive-black;

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}

.title {
    font-size: 14px;
}

.subtitle {
    font-size: 12px;
    height: 15px;
    line-height: 15px;
    margin-top: -5px;
    padding-bottom: 10px;
    color: gray;
}
</style>
