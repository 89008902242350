import Vue from 'vue';
import Vuex from 'vuex';

import genre from './modules/genre.module';
import lastMonthSearchHistory from './modules/last-month-search-history.module';
import quickSearchHistory from './modules/quick-search-history.module';
import recommendations from './modules/recommendations.module';
import filters from './modules/fillters.module';
import searchHistory from './modules/search-history.module';
import search from './modules/search.module';
import user from './modules/user.module';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const vuexStore = new Vuex.Store({
    modules: {
        genre,
        lastMonthSearchHistory,
        quickSearchHistory,
        recommendations,
        searchHistory,
        filters,
        search,
        user,
    },
    strict: debug,
    plugins: debug ? [] : [],
});

// Add store to window for angular communication
window.vuexStore = vuexStore;

export default vuexStore;
