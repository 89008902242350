<template>
    <div class="media-item-list">
        <media-item
            :show-watch-links="true"
            :item="item"
            v-for="(item, index) in visibleMediaItems"
            :key="item.id">
        </media-item>
        <span class="paginate-button" v-on:click="revealMoreItems()" v-if="visibleMediaItems.length < mediaItems.length">
            <media-item :item="mediaItems[visibleMediaItems.length]" class="preview-item">
            </media-item>
            <div class="next-media-text">
                Show Me More!
            </div>
        </span>
    </div>
</template>

<script>
import take from 'lodash/take';
import MediaItem from './media-item/media-item';

export default {
    name: 'MediaItemList',
    props: ['mediaItems', 'mediaType', 'paginate', 'paginationSize'],
    data () {
        return {
            numberOfItemsToShow: this.paginationSize || 12,
            numberOfItemsToShowIncrement: this.paginationSize || 12,
        };
    },
    computed: {
        visibleMediaItems () {
            return take(this.mediaItems, this.numberOfItemsToShow);
        },
        nextItemCover () {
            if (this.visibleMediaItems.length >= this.mediaItems.length) return null;

            return this.mediaItems[this.visibleMediaItems.length].MALimageLink;
        },
    },
    methods: {
        revealMoreItems () {
            this.numberOfItemsToShow += this.numberOfItemsToShowIncrement;
        },
    },
    components: {
        MediaItem,
    },
};
</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.media-item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32%);
    grid-template-rows: repeat(auto-fill, 250px);
    grid-gap: 1.5%;
    row-gap: 20px;
    justify-content: flex-start;

    @include responsiveMediaQuery(1150px, 1600px) {
        grid-template-columns: repeat(auto-fill, 48%);
    }

    @include responsiveMediaQuery(0, 1149px) {
        grid-template-columns: repeat(auto-fill, 100%);
        grid-gap: 20px;
    }

    @include phone() {
        justify-content: space-around;
        padding: 0px 20px;
    }

    @include tablet() {
        justify-content: start;
        padding: 0px 20px;
    };

    .paginate-button {
        cursor: pointer;
        position: relative;
        height: 250px;
        display: flex;

        .media-item.preview-item {
            margin-right: 0px;
        }

        .next-media-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            top: 0px;
            left: 0px;
            justify-content: center;
            background-color: transparentize($verdegris, 0.2);
            color: white;
            font-size: 40px;
            font-weight: bold;
            height: 100%;
            width: 100%;
            text-align: center;
            text-transform: capitalize;
            transition-duration: 0.4s, 0.4s;
            transition-property: background-color;
            transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), cubic-bezier(0.25, 0.8, 0.25, 1);

            &:hover, &:active {
                background-color: transparentize($verdegris, 0.5);
            }
        }

        @include phone() {
            margin: 0 10px 20px 0;
            width: 100%;
        };
    }
}

</style>
