import Vue from 'vue';
import Router from 'vue-router';
import LandingPage from '@/pages/landing-page';
import RecommendationsPage from '@/pages/recommendations-page';
import SearchHistory from '@/components/search-history/search-history';
import UserListView from '@/components/user-list-view/user-list-view';
import UserSearchView from '@/components/user-search-view';

Vue.use(Router);

export default new Router({
    routes: [{
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
    }, {
        path: '/app/recommendations',
        name: 'RecommendationsPage',
        component: RecommendationsPage,
    }, {
        path: '/app/stats',
        name: 'SearchHistory',
        component: SearchHistory,
    }, {
        path: '/app/user/',
        name: 'UserSearch',
        component: UserSearchView,
    }, {
        path: '/app/user/:username',
        name: 'UserList',
        component: UserListView,
    }],
    mode: 'history',
});
