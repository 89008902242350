<template>
    <div class="site-intro">
        <div class="alert">
            #blacklivesmatter - Matching donations up to $50!
            <br>Tweet a receipt of your donation to any charity focused on racial justice to
            <a href="https://twitter.com/anirecsupport" target="_blank">@AnirecSupport</a> and we'll match it up to $50.
            <br>Not sure where to donate? <a href="https://secure.actblue.com/donate/movement-4-black-lives-1" target="_blank">A network of charities is a great place to start</a>
        </div>
        <patch-notes></patch-notes>
        <h3 class="section-heading getting-started">
            Getting started
        </h3>
        <div class="indent answer">
           Type an anime, manga, or <a href="http://myanimelist.net/" target="_blank">MyAnimeList</a> username in the bar above to get started!
        </div>
        <h3 class="section-heading">MyAnimeList Integration</h3>
        <div class="indent answer">
            Syncing your profile from MyAnimeList allows Anirec to provide recommendations based on your specific preferences.
            It also lets you see a bunch of interesting statistics about how you watch anime.
            <a class="bold small-text" tabindex="0" @click="showAnswer = !showAnswer">How does it work?</a>
        </div>
        <div class="indent answer" v-if="showAnswer">
            Your recommendations are based on how you rate the anime/manga on your personal list.
            This data is then <a href="https://en.wikipedia.org/wiki/Normalization_(statistics)" target="_blank">normalized</a> and
            compared to the lists of other users. From this comparison, we can
            derive a mathematical similarity in taste between two users, and
            recommend things those other users enjoyed. Recommendations from many "highly compatible"
            users are compiled, and the winning recommendations are displayed to you! The goal
            here is to remove personal bias by sourcing recommendations from many different
            people, and recommend based solely on mathematical similarity. There is, of
            course, no real way to account for taste and personal preference, but hopefully
            this app will provide you with a starting point for finding things you like.
        </div>
        <div class="seasonal-recommendations" v-if="seasonalRecs">
            <h3 class="section-heading seasonal-recs">What's hot this season ({{ currentSeason }})</h3>
            <media-item-list
                :media-items="seasonalRecs"
                pagination-size="3"/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PatchNotes from './patch-notes';
import MediaItemList from '@/components/media-item-list/media-item-list';

export default {
    name: 'SiteIntro',
    data () {
        return {
            showAnswer: false,
        };
    },
    components: { PatchNotes, MediaItemList },
    created () {
        this.getSeasonalRecommendations();
    },
    computed: {
        ...mapState({
            seasonalRecs: state => state.recommendations.seasonalRecs
        }),
        currentSeason () {
            return this.buildSeasonStringFromDate(new Date());
        }
    },
    methods: {
        ...mapActions('recommendations', ['getSeasonalRecommendations']),
        buildSeasonStringFromDate (date) {
            const monthNum = date.getMonth() + 1;
            const year = date.getFullYear();
            let season = 'Fall';

            if (monthNum <= 3) {
                season = 'Winter';
            } else if (monthNum <= 6) {
                season = 'Spring';
            } else if (monthNum <= 9) {
                season = 'Summer';
            }

            return `${season} ${year}`;
        }
    }
};

</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.site-intro {
    line-height: 22px;
    padding: 0px 20px;

    @include desktop() {
        padding: 0;
    };

    .heading {
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 15px;
    }

    .section-heading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 5px;
    }

    .getting-started {
        margin-top: 10px;
    }

    .seasonal-recs {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .indent {
        padding: 5px 15px;
        margin-left: 20px;
        border-left: 10px solid $olive-black;
        margin-top: 10px;
        margin-bottom: 10px;

        &.answer {
            font-size: 20px;
        }
    }

    .description {
        display: inline-block;
        font-size: 18px;
    }

    .question {
        display: inline-block;
        cursor: pointer;
        color: $link-color;

        &:hover {
            text-decoration: underline;
        }
    }

    .small-text {
        font-size: 14px;
        cursor: pointer;
    }

    .alert, .announcement {
        padding: 20px;
        border-radius: 5px;
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
    }

    .alert {
        background-color: rgba(255, 255, 0, 0.22);
    }

    .announcement {
        background-color: lighten($verdegris, 30);
    }

    .seasonal-recommendations {
        padding-bottom: 20px;
    }
}

</style>
