<template>
    <div id="user-list-view">
        <div class="user-info">
            <profile-overview
                :user-id="userId"
                :animeListObj="animeListObj"
                :mangaListObj="mangaListObj"
                :username="username">
            </profile-overview>
        </div>
        <div class="list-stats">
            <div class="top-level-stats">
                <current-season-entries
                    :personal-media-list="animeListObj">
                </current-season-entries>
            </div>

            <div class="media-stats">
                <anime-manga-tabs
                    class="time-spent-by-score-chart"
                    split-tabs-width="1620"
                    :manga-tab-title="'Total chapters of manga read'"
                    :anime-tab-title="'Total time spent watching anime'">
                    <time-spent-by-score slot="anime-tab"
                        media-type="anime"
                        :personal-media-list="animeListObj"
                        :username="username">
                    </time-spent-by-score>
                    <time-spent-by-score slot="manga-tab"
                        media-type="manga"
                        :personal-media-list="mangaListObj"
                        :username="username">
                    </time-spent-by-score>
                </anime-manga-tabs>
                <anime-manga-tabs
                    class="completion-heatmap-chart"
                    split-tabs-width="1620"
                    manga-tab-title="Reading history over the past 5 years"
                    anime-tab-title="Viewing history over the past 5 years">
                    <completion-heatmap slot="anime-tab"
                        :personal-media-list="animeListObj"
                        media-type="anime">
                    </completion-heatmap>
                    <completion-heatmap slot="manga-tab"
                        :personal-media-list="mangaListObj"
                        media-type="manga">
                    </completion-heatmap>
                </anime-manga-tabs>
                <most-compatible-users
                    :username="username"
                    :compatible-users="compatibleUsers">
                </most-compatible-users>
                <generic-tabs :tabs="userListsTabs">
                    <condensed-media-list
                        slot="anime-tab"
                        :personal-media-list="animeList"
                        media-type="anime">
                    </condensed-media-list>
                    <condensed-media-list
                        slot="manga-tab"
                        :personal-media-list="mangaList"
                        media-type="manga">
                    </condensed-media-list>
                    <condensed-media-list
                        slot="planned-anime-tab"
                        :personal-media-list="planToWatchList"
                        empty-message="plan to watch list is empty"
                        media-type="anime">
                    </condensed-media-list>
                    <condensed-media-list
                        slot="planned-manga-tab"
                        :personal-media-list="planToReadList"
                        empty-message="plan to read list is empty"
                        media-type="manga">
                    </condensed-media-list>
                </generic-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import get from 'lodash/get';

import AnimeMangaTabs from './anime-manga-tabs';
import GenericTabs from './generic-tabs';
import ProfileOverview from './profile-overview';
import CompletionHeatmap from './completion-heatmap';
import MostCompatibleUsers from './most-compatible-users';
import CurrentSeasonEntries from './current-season-entries';
import TimeSpentByScore from './time-spent-by-score';
import CondensedMediaList from './condensed-media-list/condensed-media-list';

export default {
    name: 'UserListView',
    components: {
        AnimeMangaTabs,
        GenericTabs,
        ProfileOverview,
        CompletionHeatmap,
        MostCompatibleUsers,
        CurrentSeasonEntries,
        TimeSpentByScore,
        CondensedMediaList,
    },
    created () {
        this.username = this.$route.params.username;
        this.loadUserProfile({ username: this.username });
        this.loadCompatibleUsers({ username: this.username });
        this.loadUserList({ username: this.username, type: 'anime' });
        this.loadUserList({ username: this.username, type: 'manga' });
    },
    data () {
        return {
            username: null,
        };
    },
    computed: {
        ...mapState({
            userProfile: state => state.user.userProfile,
            compatibleUsers: state => state.user.compatibleUsers,
            animeListObj: state => state.user.animeList,
            mangaListObj: state => state.user.mangaList,
        }),
        userId () {
            if (!this.userProfile || !this.userProfile.data) return null;

            return this.userProfile.data.id;
        },
        episodesOfAnimeWatched () {
            return this.computeNumberOfMediaEntriesConsumed(this.animeList.list);
        },
        chaptersOfMangaRead () {
            return this.computeNumberOfMediaEntriesConsumed(this.mangaList.list);
        },
        animeList () {
            return get(this.animeListObj, 'list', []).filter(anime => anime.MALstatus !== 6);
        },
        mangaList () {
            return get(this.mangaListObj, 'list', []).filter(manga => manga.MALstatus !== 6);
        },
        planToWatchList () {
            const animeList = get(this.animeListObj, 'list', []);
            return this.buildPlannedList(animeList);
        },
        planToReadList () {
            const mangaList = get(this.mangaListObj, 'list', [])
            return this.buildPlannedList(mangaList);
        },
        showPlannedTabs () {
            return this.planToWatchList.length || this.planToReadList.length;
        },
        userListsTabs () {
            const animeMangaListTabs = [{
                tabLabel: 'Anime',
                title: `Anime list (${this.animeList.length} entries)`,
                slotName: 'anime-tab'
            }, {
                tabLabel: 'Manga',
                title: `Manga list (${this.mangaList.length} entries)`,
                slotName: 'manga-tab'
            }];

            if(!this.planToWatchList.length && !this.planToReadList.length) return animeMangaListTabs;

            const plannedListTabs = [{
                tabLabel: 'Plan to Watch',
                title: `Planned anime list (${this.planToWatchList.length} entries)`,
                slotName: 'planned-anime-tab'
            }, {
                tabLabel: 'Plan to Read',
                title: `Planned manga list (${this.planToReadList.length} entries)`,
                slotName: 'planned-manga-tab'
            }];

            return [
                ...animeMangaListTabs,
                ...plannedListTabs
            ];
        }
    },
    methods: {
        ...mapActions('user', ['loadUserProfile', 'loadUserList', 'loadCompatibleUsers']),
        computeNumberOfMediaEntriesConsumed (list) {
            return list.reduce((acc, media) => acc + media.percentComplete, 0);
        },
        buildPlannedList (list) {
            return list.filter(item => item.MALstatus === 6)
                .slice()
                .map(item => {
                    item.createdAtTimestamp = (new Date(item.createdAt)).getTime();
                    return item;
                })
                .sort((a, b) => {
                    return b.createdAtTimestamp - a.createdAtTimestamp;
                });
        }
    },
};

</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

#user-list-view {
    padding: 20px 0px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include tablet() {
        flex-direction: column;
    };

    @include phone() {
        flex-direction: column;
    };
}

.user-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 0;
    width: 265px;
    padding-right: 40px;
    box-sizing: border-box;

    @include tablet() {
        flex-direction: row;
        padding-right: 0px;
        width: 100%;
    };

    @include phone() {
        flex-direction: row;
        padding-right: 0px;
        width: 100%;
    };
}

.highlight-stats {
    @include tablet() {
        flex-direction: row !important;
    };

    @include phone() {
        flex-direction: row !important;
    };

    ::v-deep .media-type-stats {
        @include tablet() {
            margin-right: 30px;
        };

        @include phone() {
            margin-right: 30px;
        };
    }
}

.list-stats {
    display: flex;
    flex-direction: column;

    @include tablet() {
        margin-top: 30px;
    };

    @include phone() {
        margin-top: 30px;
    };
}

.top-level-stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.current-season-entries {
    margin-bottom: 40px;
}

.time-spent-by-score-chart {
    width: 100%;
    margin-bottom: 50px;
}

.completion-heatmap-chart {
    margin-bottom: 50px;

    ::v-deep .both-tabs {
        justify-content: flex-start;

        .anime-tab, .manga-tab {
            width: 50%;
        }
    }
}

.most-compatible-users {
    margin-bottom: 50px;
}

.condensed-media-list {
    height: 85vh;
}
</style>
