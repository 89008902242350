import axios from 'axios';

const initialState = {
    loading: false,
    error: false,
    searchHistoryEntries: [],
};

const getters = {

};

const actions = {
    loadSearchHistory ({ commit }) {
        commit('loading', true);

        axios.get('/api/get-search-history').then((response) => {
            commit('loading', false);
            commit('recieved', response.data);
        })
        .catch((err) => {
            commit('loading', false);
            commit('failed', err);
        });
    },
};

const mutations = {
    loading (state, isLoading) {
        state.loading = isLoading;
    },
    recieved (state, searchHistoryEntries) {
        state.searchHistoryEntries = searchHistoryEntries;
    },
    failed (state, error) {
        state.error = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
