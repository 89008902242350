<template>
    <div id="anirec-header">
        <div class="header-bar">
            <div class="logo" aria-label="Go to homepage"
                @click="pickNewTopBarImageNumber()">
                <img class="background" :src="`/images/${topBarImageNumber}.png`">
                <img class="logo-img" src="/images/logo.svg">
            </div>
            <div class="header-contents">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import sample from 'lodash/sample';
import range from 'lodash/range';

export default {
    name: 'AnirecHeader',
    components: {},
    data () {
        return {
            topBarImageNumber: sample(range(1, 9)),
        };
    },
    methods: {
        pickNewTopBarImageNumber () {
            if (!this.topBarImageNumber) {
                this.topBarImageNumber = sample(range(1, 9));
                return;
            }

            let newNumber = this.topBarImageNumber;
            while (this.topBarImageNumber === newNumber) {
                newNumber = sample(range(1, 9));
            }

            this.topBarImageNumber = newNumber;
            this.$router.push({ name: 'LandingPage' });
        },
    },
};

</script>

<style scoped lang="scss">
@import "../mixins/all.scss";

#anirec-header {
    display: flex;
    flex-direction: row;
    background-color: $olive-black;
    height: 100px;
    box-sizing: border-box;
}

.header-bar {
    max-width: 1600px;
    width: 100%;
    margin: 0px auto;
    display: flex;
}

.logo {
    width: 252px;
    height: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include phone() {
        width: 141px;
    };

    @include tablet() {
        width: 141px;
    };

    .background {
        height: 100px;
        width: 175px;

        @include phone() {
            display: none;
        };

        @include tablet() {
            display: none;
        };
    }

    .logo-img {
        height: 42px;
        width: 141px;
        margin-left: -64px;

        @include phone() {
            margin-left: 0px;
        };

        @include tablet() {
            margin-left: 0px;
        };
    }
}

.header-contents {
    margin-top: 2px;
    margin-left: 20px;
    width: 100%;

    @include phone() {
        margin-left: 0px;
    };
}

</style>
