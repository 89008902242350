<template>
    <div class="rec-algorithm-picker">
        <div
            class="backdrop"
            @click="closeModal">
        </div>
        <div class="modal">
            <div class="title">
                Recommendation Algorithm
            </div>
            <div class="rec-algorithm-option" v-for="option in recAlgorithmOptions" :key="option.key">
                <label>
                    <input
                        type="radio"
                        :value="option.key"
                        v-model="selectedOption"
                        name="rec-algorithm-choice"/>
                    {{ option.label }}
                    <div class="algorithm-choice-subtext">
                        {{ option.descriptionText }}
                    </div>
                </label>
            </div>
            <div class="button-bar">
                <material-button
                    class="update-btn"
                    :raised="true"
                    :ripple="true"
                    :large="true"
                    @click="closeModal">
                    Select Algorithm
                </material-button>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from 'vue-material-button';

export default {
    name: 'RecAlgorithmPicker',
    props: ['algorithm'],
    components: {
        MaterialButton,
    },
    data () {
        return {
            recAlgorithmOptions: [{
                key: 'popular',
                label: 'Score + Popularity',
                selected: false,
                // eslint-disable-next-line
                descriptionText: 'The best choice for general recommendations - after matching you with people of similar taste, this algorithm looks for strong recommendations by high frequency of good scores.',
            }, {
                key: 'rawScore',
                label: 'Strictly Score',
                selected: false,
                // eslint-disable-next-line
                descriptionText: 'Similar to "Score + Popularity", after matching you with people of similar taste, this algorithm looks for the highest rated content, even if only a few people have it on their list.',
            }, {
                key: 'genre',
                label: 'Genre',
                selected: false,
                // eslint-disable-next-line
                descriptionText: 'After matching you with people of similar taste, this algorithm weights heavily towards genre that you\'ve rated highly before. Because of the increase in necessary data/computation, this is also the slowest algorithm.',
            }],
            selectedOption: null,
        };
    },
    created () {
        this.selectedOption = this.algorithm;
        window.addEventListener('keydown', this.escapeListener);
    },
    beforeDestroy () {
        window.removeEventListener('keydown', this.escapeListener);
    },
    methods: {
        closeModal () {
            this.$emit('close', { algorithm: this.selectedOption });
        },
        escapeListener (event) {
            if(event.keyCode === 27) {
                this.closeModal();
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";
.title {
    font-size: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparentize($olive-black, 0.9);
    margin-bottom: 5px;
}

.backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: transparentize($isabelline, 0.1);
    z-index: 10;
    top: 0;
    left: 0;
}

.rec-algorithm-picker {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    box-sizing: border-box;

    .modal {
        width: 550px;
        z-index: 11;
        background-color: $isabelline;
        padding: 50px;
        border: 1px solid transparentize($olive-black, 0.8);
    }

    .title {
        font-size: 18px;
    }
    .rec-algorithm-option {
        margin-bottom: 20px;
    }

    .algorithm-choice-subtext {
        font-size: 14px;
        color: transparentize($olive-black, 0.4);
        margin-left: 28px;
    }
}

.button-bar {
    display: flex;
    justify-content: flex-end;

    .update-btn {
        // width: 150px;

        ::v-deep .material-button {
            padding: 0px 20px;
        }
    }
}
</style>
