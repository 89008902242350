<template>
    <div class="rec-type-picker">
        <el-radio-group v-model="localMediaType">
            <el-radio-button label="anime">
                Anime
            </el-radio-button>
            <el-radio-button label="manga">
                Manga
            </el-radio-button>
        </el-radio-group>
    </div>
</template>

<script>
import { RadioGroup, RadioButton } from 'element-ui';

export default {
    name: 'RecTypePicker',
    props: ['mediaType'],
    components: {
        ElRadioGroup: RadioGroup,
        ElRadioButton: RadioButton,
    },
    data () {
        return {
            localMediaType: this.mediaType,
        };
    },
    watch: {
        localMediaType (mediaType) {
            this.$emit('onChange', { mediaType });
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.description {
    font-size: 16px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid transparentize($olive-black, 0.9);
    margin-bottom: 5px;
}

::v-deep .el-radio-group {
    .el-radio-button {
        .el-radio-button__inner {
            @include phone() {
                padding: 12px 10px;
            }
        }

        &:focus,
        &.is-active:focus {
            box-shadow: none;
            z-index: 11;

            .el-radio-button__inner {
                outline: 2px solid $verdegris;
            }
        }

        &.is-active {
            .el-radio-button__inner {
                background-color: $verdegris;
                color: lighten($isabelline, 10);
                z-index: 10;
                box-shadow: none;

                &:hover {
                    color: $isabelline;
                }
            }
        }

        &:first-child {
            border-radius: 2px 0px 0px 2px;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
            transition: box-shadow 200ms linear;

            &:hover {
                box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15) !important;
            }

            .el-radio-button__inner {
                border-radius: 2px 0px 0px 2px;
                border: none;
            }
        }

        &:last-child {
            border-radius: 0px 2px 2px 0px;
            box-shadow: 2px 2px 5px 0 rgba(0,0,0,.16), 2px 2px 10px 0 rgba(0,0,0,.12) !important;
            transition: box-shadow 200ms linear;

            &:hover {
                box-shadow: 3px 5px 11px 0 rgba(0,0,0,.18), 3px 4px 15px 0 rgba(0,0,0,.15) !important;
            }

            .el-radio-button__inner {
                border-radius: 0px 2px 2px 0px;
                border: none;
            }
        }

        .el-radio-button__inner {
            border: 1px solid transparentize($olive-black, 0.5);
            background-color: lighten($isabelline, 3);
            color: $olive-black;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 16px;
        }
    }
}

.media-list-choices {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
        width: 120px;
    }
}

</style>
