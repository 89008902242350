import axios from 'axios';

export function unicodeFilter (input) {
    return input.replace(/\\u[\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef]/g,
        match => ` ${String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))} `);
}

export async function getMediaById (mediaIds) {
    const result = await axios.get(`/api/media?ids=${mediaIds}`);
    return result.data;
}
