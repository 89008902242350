<template>
    <div class="generic-tabs">
        <div class="tab-bar">
            <div class="tabs">
                <div class="tab-item"
                    :class="{ underline: isTabSelected(tab) }"
                    v-for="tab in tabs"
                    :key="tab.slotName"
                    @click="selectTab(tab)">
                    {{ tab.tabLabel }}
                </div>
            </div>
            <div class="tab-title">
                {{ selectedTab.title }}
            </div>
        </div>
        <div class="tab-content">
            <slot :name="selectedTab.slotName"></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GenericTabs',
    components: { },
    props: ['tabs'],
    data () {
        return {
            selectedTabName: null
        };
    },
    created () {
        this.selectedTabName = this.tabs[0].slotName;
    },
    computed: {
        selectedTab () {
            return this.tabs.find(tab => tab.slotName === this.selectedTabName)
        }
    },
    methods: {
        selectTab (tab) {
            this.selectedTabName = tab.slotName;
        },
        isTabSelected (tab) {
            return tab.slotName === this.selectedTabName;
        }
    }
};

</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.tab-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    user-select: none;
    min-width: 400px;

    .tabs {
        display: flex;
        flex-direction: row;
    }

    .tab-item {
        margin-right: 30px;
        padding-bottom: 4px;
        cursor: pointer;
        font-size: 18px;

        &.underline {
            border-bottom: 3px solid $tufts-blue;
        }
    }

    .tab-title {
        font-size: 18px;
        font-style: italic;
    }
}
</style>
