<template>
    <!-- chart.js is a piece of shit and can't set its own height -->
    <div id="search-history-chart" style="height: 400px;">
        <canvas style="height: 400px;"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js';
import moment from 'moment';

export default {
    props: ['search-history-entries', 'quick-search-history-entries'],
    watch: {
        searchHistoryEntries () {
            return this.buildChart();
        },
        quickSearchHistoryEntries () {
            return this.buildChart();
        },
    },
    methods: {
        buildChart () {
            if (!this.searchHistoryEntries || !this.quickSearchHistoryEntries) return null;
            const labels = Object.keys(this.searchHistoryEntries).map(timestamp => moment(parseInt(timestamp)));
            const chartEle = document.querySelector('#search-history-chart > canvas').getContext('2d');
            const conf = {
                type: 'line',
                data: {
                    labels,
                    datasets: [{
                        lineTension: 0,
                        label: 'Search History',
                        data: Object.values(this.searchHistoryEntries).map(arr => arr.length),
                        fill: false,
                        borderColor: '#35495e',
                    }, {
                        lineTension: 0,
                        label: 'Quick Search History',
                        data: Object.values(this.quickSearchHistoryEntries).map(arr => arr.length),
                        fill: false,
                        borderColor: '#42b983',
                    }],
                },
                options: {
                    responsive: true,
                    animation: false,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            type: 'time',
                            unit: 'day',
                            unitStepSize: 1,
                            time: {
                                displayFormats: {
                                    day: 'MMM DD',
                                },
                            },
                        }],
                    },
                },
            };

            return new Chart(chartEle, conf);
        },
    },
};
</script>
