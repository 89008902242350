import axios from 'axios';

const initialState = {
    includeGenres: [],
    excludeGenres: [],
    type: 'anime',
    options: null,
};

const mutations = {
    setIncludeGenres (state, { includeGenres }) {
        state.includeGenres = includeGenres;
    },
    setExcludeGenres (state, { excludeGenres }) {
        state.excludeGenres = excludeGenres;
    },
    setOptions (state, { options }) {
        state.options = options;
    },
    setType (state, { type }) {
        state.type = type;
    },
};

const actions = {
    async getGenreStrings ({ commit }) {
        const response = axios.get('/api/genre');

        commit('setOptions', { options: response.data });
    },
    async getGenresForType ({ state, commit }, { type }) {
        const filterPayload = {
            include: state.includeGenres,
            exclude: state.excludeGenres,
        };

        const response = await axios.post(`/api/genre/${type}`, filterPayload);

        commit('setOptions', { options: response.data });
        commit('setType', { type });
    },
    resetGenreFilter ({ dispatch, state, commit }) {
        commit('setIncludeGenres', { includeGenres: [] });
        commit('setExcludeGenres', { excludeGenres: [] });
        dispatch('getGenresForType', { type: state.type });
    },
    async addIncludeGenre ({ state, commit, dispatch }, { id }) {
        if (state.includeGenres.includes(id)) return;

        if (state.excludeGenres.includes(id)) {
            await dispatch('removeExcludeGenre', { id });
        }

        commit('setIncludeGenres', { includeGenres: state.includeGenres.concat([id]) });
        await dispatch('getGenresForType', { type: state.type });
    },
    async addExcludeGenre ({ state, commit, dispatch }, { id }) {
        if (state.excludeGenres.includes(id)) return;

        if (state.includeGenres.includes(id)) {
            await dispatch('removeIncludeGenre', { id });
        }

        commit('setExcludeGenres', { excludeGenres: state.excludeGenres.concat([id]) });
        await dispatch('getGenresForType', { type: state.type });
    },
    async removeIncludeGenre ({ state, commit, dispatch }, { id }) {
        const includeGenres = state.includeGenres.filter(genreId => genreId !== id);
        await commit('setIncludeGenres', { includeGenres });
        await dispatch('getGenresForType', { type: state.type });
    },
    async removeExcludeGenre ({ state, commit, dispatch }, { id }) {
        const excludeGenres = state.excludeGenres.filter(genreId => genreId !== id);
        await commit('setExcludeGenres', { excludeGenres });
        await dispatch('getGenresForType', { type: state.type });
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
