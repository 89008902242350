<template>
    <div id="recommendations">
        <hr v-if="usingUserSpecificRecommendations" />
        <div class="recommendation-block" v-if="usingUserSpecificRecommendations">
            <div class="category-title">
                <div class="heading">
                    <h2 class="title">Concensus Recommendations</h2>
                    <div class="subtitle">Users similar to you all agree, these are quality {{ mediaType }}</div>
                </div>
            </div>
            <media-item-list
                v-if="consensusRecommendations && consensusRecommendations.length"
                :paginate="true"
                :media-items="consensusRecommendations">
            </media-item-list>
            <div v-if="!consensusRecommendations || !consensusRecommendations.length" class="no-results">
                No results were found for this category.
            </div>
        </div>
        <hr v-if="usingUserSpecificRecommendations" />
        <div class="recommendation-block" v-if="usingUserSpecificRecommendations">
            <div class="category-title">
                <div class="heading">
                    <h2 class="title">Polarizing {{mediaType}}</h2>
                    <div class="subtitle">Some people loved these {{ mediaType }}, some people hated them.</div>
                </div>
            </div>
            <media-item-list
                v-if="highVarianceRecommendations && highVarianceRecommendations.length"
                :paginate="true"
                :media-items="highVarianceRecommendations">
            </media-item-list>
            <div v-if="!highVarianceRecommendations || !highVarianceRecommendations.length" class="no-results">
                No results were found for this category.
            </div>
        </div>
        <hr v-if="!usingUserSpecificRecommendations" />
        <div class="recommendation-block" v-if="!usingUserSpecificRecommendations && quickRecommendations">
            <div class="category-title">
                <div class="heading">
                    <h2 class="title">Recommendations</h2>
                    <div class="subtitle">People that enjoyed <span v-html="mediaChoiceDisplayString"></span>also enjoyed these {{ mediaType }}</div>
                </div>
            </div>
            <media-item-list
                :paginate="true"
                :media-items="quickRecommendations">
            </media-item-list>
        </div>
    </div>
</template>

<script>
import MediaItemList from '@/components/media-item-list/media-item-list';

export default {
    name: 'Recommendations',
    props: ['recommendations', 'mediaChoiceType', 'mediaType', 'mediaChoices'],
    components: {
        MediaItemList,
    },
    computed: {
        highVarianceRecommendations () {
            return this.recommendations.highVariance;
        },
        consensusRecommendations () {
            return this.recommendations.consensus;
        },
        quickRecommendations () {
            return this.recommendations.quickRecommendations;
        },
        usingUserSpecificRecommendations () {
            return this.mediaChoiceType === 'user';
        },
        mediaChoiceDisplayString () {
            let displayString = '';

            if (this.mediaChoices.length === 1) {
                return `<b>${this.mediaChoices[0].title}</b> `;
            }

            if (this.mediaChoices.length === 2) {
                return `<b>${this.mediaChoices[0].title}</b> and <b>${this.mediaChoices[1].title}</b> `;
            }

            for (let i = 0; i < this.mediaChoices.length - 1; i++) {
                displayString += `<b>${this.mediaChoices[i].title}</b>, `;
            }

            displayString += `and <b>${this.mediaChoices[this.mediaChoices.length - 1].title}</b> `;
            return displayString;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../mixins/all.scss';

hr {
    margin: 0px 00px;
    border: none;
    border-top: 1px solid lighten($olive-black, 70);
    height: 0px;
}

#recommendations {
    padding-top: 20px;

    .recommendation-block {
        padding-bottom: 25px;
    }

    .category-title {
        margin-bottom: 10px;

        @include phone() {
            padding: 0px 20px;
        };

        @include tablet() {
            padding: 0px 20px;
        };

        .heading {
            padding: 10px 0px;
        }

        .title {
            margin: 0px;
            font-size: 35px;
            text-transform: capitalize;
        }

        .subtitle {
            font-size: 16px;
        }
    }

    .no-results {
        color: $sea-green;
        font-size: 20px;
        padding-left: 20px;
    }

    ::v-deep .media-item-list {
        ::v-deep .media-item {
            background-color: darken($isabelline, 3)
        }
    }
}
</style>
