<template>
    <div class="sync-list-button" v-tooltip="buttonTooltip">
        <material-button class="sync-list-btn"
            :disabled="recentlySynced || userSync.loading"
            :raised="true"
            :ripple="true"
            :large="true"
            @click="listSyncClick">
            <div class="button-text">
                {{ buttonText }}
            </div>
            <div class="sync-text" v-if="this.userProfile && this.userProfile.listLastUpdatedAt">
                Last Sync: {{ timeSinceLastSync }}
            </div>
        </material-button>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import MaterialButton from 'vue-material-button';

export default {
    name: 'SyncListButton',
    props: ['username'],
    components: { MaterialButton },
    computed: {
        ...mapState({
            userSync: state => state.user.userListSync,
            userProfile: state => state.user.userProfile.data,
        }),
        recentlySynced () {
            if (!this.userProfile) return false;

            if (!this.userProfile.listLastUpdatedAt) return false;

            const now = new Date();
            const fiveMinutesInMs = 1000 * 60 * 5;

            if (now - new Date(this.userProfile.listLastUpdatedAt) > fiveMinutesInMs) return false;

            return true;
        },
        buttonText () {
            if (this.userSync.loading) {
                return 'Syncing with MyAnimeList...';
            }

            return 'Sync with MyAnimeList';
        },
        buttonTooltip () {
            if (this.recentlySynced) {
                // add 6 minutes to account for rounding
                const canSyncAt = moment(this.userProfile.listLastUpdatedAt).add(6, 'minutes').format('h:mma');
                return `Information for ${this.username} can be synced again at ${canSyncAt}`;
            }

            return '';
        },
        timeSinceLastSync () {
            const now = moment();
            const lastUpdatedAt = moment(this.userProfile.listLastUpdatedAt);

            const diffInDays = now.diff(lastUpdatedAt, 'days');
            const isNextDay = lastUpdatedAt.day() !== now.day();
            if (diffInDays || isNextDay) return lastUpdatedAt.format('ddd, MMM Do');

            return lastUpdatedAt.format('h:mma');
        },

    },
    methods: {
        ...mapActions('user', ['syncUserLists']),
        async listSyncClick () {
            await this.syncUserLists({ username: this.username });
            this.$emit('listSync');
        },
    },
};

</script>
<style scoped lang="scss">
@import '../mixins/all.scss';

.sync-list-btn {
    ::v-deep .material-button {
        height: initial;
        min-height: 36px;
        padding: 0px 5px;
        width: 100%;
        background-color: lighten($isabelline, 3);
        color: $olive-black;

        &:hover {
            background-color: lighten($isabelline, 4);
        }

        &:disabled {
            color: transparentize($olive-black, 0.6);
            cursor: not-allowed;
        }
    }
}

.button-text {
    font-size: 15px;
}

.sync-text {
    font-size: 12px;
    height: 15px;
    line-height: 15px;
    margin-top: -5px;
    padding-bottom: 10px;
    color: gray;
}

</style>
