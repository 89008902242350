<template>
    <div class="media-detail-table">
        <div class="table-row heading">
            <div class="media-type-column">
                {{ mediaType }} <span class="normal">({{ entryList.length }} entries)</span>
            </div>
            <div class="secondary-column">
                {{ secondaryColumnLabel }}
                <div class="close" @click="emitCloseEvent()">
                    [close]
                </div>
            </div>
        </div>
        <div class="table-body" :style="{ height: `${tableBodyHeight}px` }">
            <div class="table-row" v-for="entry in entryList">
                <div class="title">
                    <a class="title-link" :href="`https://myanimelist.net/${mediaType}/${entry.MALid}`" target="_blank">{{ entry.title }}</a>
                </div>
                <div class="secondary-info">
                    {{ entry[secondaryInfoKey] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MediaDetailTable',
    components: {},
    props: ['entryList', 'mediaType', 'secondaryColumnLabel', 'secondaryInfoKey', 'rowsToShow'],
    methods: {
        emitCloseEvent () {
            this.$emit('close');
        },
    },
    computed: {
        tableBodyHeight () {
            const rowHeight = 28;

            const visibleRows = Math.min(this.entryList.length, parseInt(this.rowsToShow, 10));

            return (visibleRows * rowHeight) + 2;
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

$close-width: 65px;

$column-1-width: 75%;
$column-1-min-width: 250px;

$column-2-width: 20%;
$column-2-min-width: 140px + $close-width;

$heading-height: 30px;
$row-height: 28px;

.normal {
    font-weight: normal;
}
.media-detail-table {
    width: 100%;

    .heading {
        font-weight: bold;
        text-align: left;
        text-transform: capitalize;
        background-color: transparentize($olive-black, 0.1);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: $isabelline;
        height: $heading-height;
    }

    .close {
        width: $close-width;
        cursor: pointer;
        text-transform: none;
        text-align: right;
        box-sizing: border-box;
        padding-right: 5px;
    }

    .heading, .table-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .title, .secondary-info, .media-type-column, .secondary-column {
        padding: 5px;
        box-sizing: border-box;
    }

    .media-type-column {
        width: $column-1-width;
        min-width: $column-1-min-width;
    }

    .secondary-column {
        width: $column-2-width;
        min-width: $column-2-min-width;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .table-body {
        overflow-y: auto;
        border: 1px solid transparentize($olive-black, 0.8);
        box-sizing: border-box;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .table-row {
            height: $row-height;

            &:nth-child(2n+1) {
                background-color: darken($isabelline, 3)
            }

            .title {
                width: $column-1-width;
                min-width: $column-1-min-width;
                padding-right: 5px;

                .title-link {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
            }

            .secondary-info {
                width: $column-2-width;
                min-width: $column-2-min-width;
                padding-right: 5px;
            }
        }
    }
}

</style>
