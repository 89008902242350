<template>
    <div id="landing-page">
        <site-intro></site-intro>
    </div>
</template>

<script>
import SiteIntro from '@/components/site-intro/site-intro';

export default {
    name: 'App',
    components: { SiteIntro }
};
</script>

<style lang="scss">
@import '../mixins/all.scss';

</style>
