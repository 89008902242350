<template>
    <div class="completion-heatmap">
        <div class="heatmap">
            <div class="month-label-row">
                <div class="month-label" v-for="(month, index) in monthArray" :key="month" :class="labelClasses(detailMonth, index)">
                    {{ month.substring(0, 3) }}
                </div>
            </div>
            <div class="year-row" v-for="(year, yearIndex) in finishDatesByYearAndMonth">
                <div class="year-label" :class="labelClasses(detailYear, yearIndex)">
                    {{ yearIndex }}
                </div>
                <div class="month" v-for="(entries, monthIndex) in year.months" :key="monthIndex">
                    <transition
                        v-on:before-appear="circleBeforeEnter"
                        v-on:before-enter="circleBeforeEnter"
                        v-on:appear="circleEnter"
                        v-on:enter="circleEnter"
                        v-on:leave="circleLeave">
                        <div v-if="entries.length"
                            class="circle"
                            :class="getCircleColorClasses(yearIndex, monthIndex, finishDateStats, entries.length)"
                            :year-index="year.index"
                            :month-index="monthIndex"
                            @click="toggleDetailTable(yearIndex, monthIndex, entries)"
                            v-tooltip="`${entries.length} ${mediaType} completed in ${monthArray[monthIndex]} of ${yearIndex}`">
                        </div>
                    </transition>
                    <div class="spacer" v-if="!entries.length"
                        v-tooltip="`No ${mediaType} completed in ${monthArray[monthIndex]} of ${yearIndex}`">
                    </div>
                </div>
            </div>
            <div class="entry-stats" v-if="!showDetailTable">
                <div>
                    Completed {{ finishDateStats.visibleEntries }} {{ mediaType }}
                    ({{ finishDateStats.visibleEpisodes }} {{ mediaType === 'anime' ? 'episodes' : 'chapters' }})
                    in the past 5 years
                </div>
                <div class="subtext" v-if="finishDateStats.unmarkedEntries">
                    {{ finishDateStats.unmarkedEntries }} entries have no completion date
                </div>
            </div>
        </div>
        <media-detail-table v-if="showDetailTable"
            :entry-list="detailTableValues"
            :media-type="mediaType"
            :secondary-column-label="'Date Completed'"
            :secondary-info-key="'completionDateLabel'"
            :rows-to-show="detailRowsToShow"
            @close="closeDetailTable">
        </media-detail-table>
    </div>
</template>

<script>
import each from 'lodash/each';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';
import random from 'lodash/random';
import moment from 'moment';

import MediaDetailTable from '@/components/user-list-view/media-detail-table';

const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default {
    name: 'CompletionHeatmap',
    components: { MediaDetailTable },
    props: ['personalMediaList', 'mediaType'],
    data () {
        return {
            monthArray,
            showDetailTable: false,
            detailTableValues: [],
            detailRowsToShow: 8,
            detailMonth: 0,
            detailYear: 0,
        };
    },
    computed: {
        mediaList () {
            return this.personalMediaList.list;
        },
        finishDatesByYearAndMonth () {
            const categorizedEntries = this.buildInitialYearAndMonthObject();

            this.mediaList.forEach((entry) => {
                if (!entry.endDate) return;
                const [endYear, endMonth] = entry.endDate.split('-');

                if (!categorizedEntries[endYear]) return;

                categorizedEntries[endYear].months[parseInt(endMonth, 10) - 1].push(entry);
            });

            return categorizedEntries;
        },
        finishDateStats () {
            const statsByMonth = [];
            const episodesByMonth = [];

            each(this.finishDatesByYearAndMonth, (year) => {
                year.months.forEach((monthEntries) => {
                    statsByMonth.push(monthEntries.length);

                    monthEntries.forEach(entry => episodesByMonth.push(entry.percentComplete));
                });
            });

            const maxResults = max(statsByMonth);
            const minResults = min(statsByMonth.filter(month => !!month));
            const visibleEntries = statsByMonth.filter(month => !!month).length;
            const visibleEpisodes = sum(episodesByMonth);
            const totalEntries = this.mediaList.length;
            const unmarkedEntries = this.mediaList.filter((entry) => {
                if (!entry.endDate) return null;

                return entry.endDate.split('-')[0] === '0000';
            }).length;
            const oldEntries = totalEntries - visibleEntries - unmarkedEntries;

            return { max: maxResults, min: minResults, visibleEntries, visibleEpisodes, totalEntries, unmarkedEntries, oldEntries };
        },
        currentYear () {
            return (new Date()).getFullYear();
        },
        currentMonth () {
            return (new Date()).getMonth();
        },
    },
    methods: {
        buildLast5YearsArray () {
            let thisYear = (new Date()).getFullYear();
            const yearArray = [];
            for (let i = 0; i < 5; i += 1) {
                yearArray.push(thisYear);
                thisYear -= 1;
            }

            return yearArray.reverse();
        },
        buildInitialYearAndMonthObject () {
            const initialObj = {};
            const last5Years = this.buildLast5YearsArray();

            last5Years.forEach((year, index) => {
                initialObj[year] = { index, months: [] };

                monthArray.forEach(() => {
                    initialObj[year].months.push([]);
                });
            });

            return initialObj;
        },
        getCircleColorClasses (yearIndex, monthIndex, finishDateStats, monthEntryCount) {
            const classes = [];

            const percentageOfMax = Math.ceil((monthEntryCount / finishDateStats.max).toFixed(1) * 5);
            classes.push(`circle-color-${percentageOfMax}`);

            const year = parseInt(yearIndex, 10);
            const month = parseInt(monthIndex, 10);

            if (year === this.detailYear && month === this.detailMonth) {
                classes.push('selected');
            }

            if (this.showDetailTable) {
                classes.push('viewing-details');
            }

            return classes.join(' ');
        },
        closeDetailTable () {
            this.showDetailTable = false;
            this.detailYear = 0;
            this.detailMonth = 0;
        },
        toggleDetailTable (year, month, entries) {
            const detailYear = parseInt(year, 10);
            const detailMonth = parseInt(month, 10);
            if (this.detailYear === detailYear && this.detailMonth === detailMonth) {
                this.closeDetailTable();
                return;
            }

            this.showDetailTable = true;
            this.detailYear = detailYear;
            this.detailMonth = detailMonth;

            const detailTableValues = entries.map((entry) => {
                entry.completionDateLabel = moment(entry.endDate).format('MMMM Do');
                return entry;
            });

            this.detailTableValues = detailTableValues.sort((entryA, entryB) => {
                const dateA = new Date(entryA.endDate);
                const dateB = new Date(entryB.endDate);

                return dateA - dateB;
            });
        },
        labelClasses (selectedTime, labelTime) {
            const classes = [];

            const intSelectedTime = parseInt(selectedTime, 10);
            const intLabelTime = parseInt(labelTime, 10);

            if (intSelectedTime === intLabelTime) {
                classes.push('selected');
            }

            if (this.showDetailTable) {
                classes.push('viewing-details');
            }

            return classes.join(' ');
        },
        circleBeforeEnter (el) {
            el.classList.add('small');
            el.classList.add('before-enter');
        },
        circleEnter (el) {
            const yearIndex = el.getAttribute('year-index');
            const monthIndex = el.getAttribute('month-index');

            const waitInterval = (random(50, 150) * yearIndex) + (random(50, 150) * monthIndex);
            setTimeout(() => {
                el.classList.remove('small');
                el.classList.remove('before-enter');
            }, waitInterval);
        },
        circleLeave (el) {
            el.classList.add('no-transition');
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";
.completion-heatmap {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 285px;
}

.heatmap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.year-row, .month-label-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.year-label, .month-label {
    &.viewing-details {
        opacity: 0.3;
    }

    &.selected {
        opacity: 1;
    }
}

.year-row {
    height: 40px;

    .year-label {
        width: 60px;
        font-weight: bold;
        animation: fadein 1s;
    }

    .month {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.month-label-row {
    padding-left: 60px;

    .month-label {
        font-weight: bold;
        width: 40px;
        text-align: center;
        padding-bottom: 10px;
        animation: fadein 1s;
    }
}

.circle, .spacer {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.spacer {
    border: 1px solid transparentize($tufts-blue, 0.9);
}

.circle {
    background-color: $tufts-blue;
    cursor: pointer;
    transition: opacity 150ms ease-in, height 300ms linear, width 300ms linear;

    &:hover {
        animation: pulse 1s infinite linear;
    }

    &.before-enter {
        opacity: 0;
    }

    &.small {
        width: 20px;
        height: 20px;
    }

    &.no-transition {
        transition: none;
    }

    &.viewing-details {
        opacity: 0.5;
    }

    &.selected {
        border: 3px solid transparentize($olive-black, 0.2);
        opacity: 1;
    }

}

.entry-stats {
    margin-top: 20px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    .subtext {
        font-size: 12px;
    }
}

.media-detail-table {
    margin-top: 10px;
}

$shades-of-blue: lighten($tufts-blue, 41), lighten($tufts-blue, 30), lighten($tufts-blue, 19), lighten($tufts-blue, 13), $tufts-blue;
@for $i from 1 through 5 {
    .circle-color-#{$i} {
        background-color: nth($shades-of-blue, $i);
    }
}

@keyframes pulse {
    25% {
        transform: scale(1.1, 1.1);
    }

    75% {
        transform: scale(0.9, 0.9);
    }
}

</style>
