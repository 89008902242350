<template>
    <div id="recommendations-page">
        <div class="side-panel" v-if="mediaChoiceType === 'user'">
            <profile-overview
                :userId="user.id"
                :animeListObj="animeListObj"
                :mangaListObj="mangaListObj"
                :username="user.username"
                :show-anirec-link="true">
            </profile-overview>
        </div>
        <div class="main-panel">
            <div class="quick-choice-preview" v-if="showChoices">
                <h2 class="based-on">
                    Based on...
                </h2>
                <condensed-media-list :personal-media-list="mediaChoices">
                    <div slot="placeholder" class="placeholder" v-if="mediaChoices.length < 8">
                        <img class="up-arrow" src="/images/arrow-up.svg" />
                        <span>
                            Add up to <b>{{ 8 - mediaChoices.length }}</b> more {{ mediaChoiceType }}
                        </span>
                    </div>
                </condensed-media-list>
            </div>
            <rec-options-panel
                @onChange="getRecommendations">
            </rec-options-panel>
            <recommendations
                v-if="recommendations && !recError"
                :recommendations="recommendations"
                :media-choices="mediaChoices"
                :media-type="mediaType"
                :media-choice-type="mediaChoiceType">
            </recommendations>
            <div class="error" v-if="recError || syncError">
                {{ recError || syncError }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ProfileOverview from '@/components/user-list-view/profile-overview';
import Recommendations from '@/components/recommendations';
import RecOptionsPanel from '@/components/rec-options-panel/rec-options-panel';
import CondensedMediaList from '@/components/user-list-view/condensed-media-list/condensed-media-list';

export default {
    name: 'App',
    components: {
        ProfileOverview,
        Recommendations,
        RecOptionsPanel,
        CondensedMediaList,
    },
    computed: {
        ...mapState({
            mediaChoices: state => state.recommendations.mediaChoices,
            mediaChoiceType: state => state.recommendations.mediaChoiceType,
            recommendations: state => state.recommendations.recommendations,
            recError: state => state.recommendations.error,
            mediaType: state => state.recommendations.mediaType,
            user: state => state.user.userProfile.data,
            mangaListObj: state => state.user.mangaList,
            animeListObj: state => state.user.animeList,
            syncError: state => state.user.userListSync.error,
        }),
        showChoices () {
            return this.mediaChoiceType === 'anime' || this.mediaChoiceType === 'manga';
        },
    },
    created () {
        if (this.mediaChoices.length) {
            this.getRecommendations();
        }
    },
    destroyed () {
        this.clearMediaChoices();
    },
    methods: {
        ...mapActions('recommendations', ['getRecommendationsForUser', 'getQuickRecommendations', 'clearMediaChoices']),
        async getRecommendations () {
            if (this.mediaChoiceType === 'user') {
                await this.getRecommendationsForUser({ username: this.user.username, type: this.mediaType });
            } else {
                await this.getQuickRecommendations({ mediaIds: this.mediaChoices.map(choice => choice.id), type: this.mediaType });
            }
        },
    },
    watch: {
        async user (newUser) {
            if (!newUser) return;

            await this.getRecommendationsForUser({ username: newUser.username, type: this.mediaType });
        },
        async mediaChoices () {
            if (this.mediaChoiceType === 'anime' || this.mediaChoiceType === 'manga') {
                this.getRecommendations();
            }
        },
    },
};
</script>

<style lang="scss">
@import '../mixins/all.scss';

#recommendations-page {
    display: flex;

    @include tablet() {
        flex-direction: column;
    }
    @include phone() {
        flex-direction: column;
    }
}

.side-panel {
    margin-top: 20px;
}

.placeholder {
    width: 150px;
    height: 214px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid transparentize($olive-black, 0.7);
    padding: 20px;
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;

    .up-arrow {
        height: 40px;
    }
}

.based-on {
    font-size: 28px;
    margin-bottom: 10px;
}

.main-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.error {
    font-size: 28px;
    margin-top: 20px;
    margin-left: 20px;
}
</style>
