<template>
    <div id="app">
        <vue-progress-bar></vue-progress-bar>
        <anirec-header>
            <omnibar v-if="showOmnibar"></omnibar>
        </anirec-header>
        <div class="router-view-container">
            <router-view class="router-view"></router-view>
        </div>
        <sync-list-modal></sync-list-modal>
    </div>
</template>

<script>
import AnirecHeader from '@/components/anirec-header';
import Omnibar from '@/components/omnibar';
import SyncListModal from '@/components/sync-list-modal';

export default {
    name: 'App',
    components: {
        AnirecHeader,
        Omnibar,
        SyncListModal,
    },
    computed: {
        showOmnibar () {
            const omnibarRoutes = ['LandingPage', 'RecommendationsPage'];
            return omnibarRoutes.includes(this.$route.name);
        },
    },
};
</script>

<style lang="scss">
@import './mixins/all.scss';

.__cov-progress {
    background-color: $tufts-blue;
    height: 5px;
}

#anirec-header {
    @include topLevelContainer();
}

.router-view-container {
    @include topLevelContainer();

    .router-view {
        max-width: 1600px;
        margin: 0 auto;
    }
}

.material-button__component {
    .material-button.material-button--raised {
        height: initial;
        min-height: 36px;
        padding: 0px 5px;
        width: 100%;
        height: 100%;
        background-color: lighten($isabelline, 3);
        color: $olive-black;

        &:hover {
            background-color: lighten($isabelline, 4);
        }

        &:disabled {
            color: transparentize($olive-black, 0.6);
            cursor: not-allowed;
        }
    }
}

body {
    /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
    background-color: $isabelline;
    color: $olive-black;
    display: flex;
    flex-direction: column;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
    min-height: 100%;
    width: 100%;
    min-width: 600px;
    padding: 0;

    a {
        color: $verdegris;
        &:focus {
            outline: none;
            text-decoration: underline;
            background-color: rgba($verdegris, 0.1);
            box-shadow: 0px 0px 0px 5px rgba($verdegris, 0.1);
        }
    }
}

.bold {
    font-weight: bold;
}

.tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    animation: fadein 200ms;
    border-radius: 5px;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

</style>
