<template>
    <div class="rec-options-panel">
        <div class="button-bar">
            <rec-type-picker
                :media-type="mediaType"
                @onChange="updateMediaType">
            </rec-type-picker>

            <material-button
                class="genre-filter-toggle"
                :raised="true"
                :ripple="true"
                :large="true"
                @click="toggleGenreFilter">
                Filters
            </material-button>

            <rec-filters
                v-if="showFilters"
                :media-type="mediaType"
                @close="toggleGenreFilter"
                @update-filter="updateFilter">
            </rec-filters>

            <material-button
                v-if="mediaChoiceType === 'user'"
                class="nerd-only-toggle"
                :raised="true"
                :ripple="true"
                :large="true"
                @click="toggleAlgorithmPicker">
                Nerds Only
            </material-button>

            <router-link
                class="start-over"
                :to="{ name: 'LandingPage' }"
                @click.native="reset">
                Start Over
            </router-link>
        </div>
        <div v-if="showAlgorithm">
            <rec-algorithm-picker
                :algorithm="algorithm"
                @close="updateRecAlgorithm">
            </rec-algorithm-picker>
        </div>
    </div>
</template>

<script>
import MaterialButton from 'vue-material-button';
import { mapMutations, mapState, mapActions } from 'vuex';
import RecTypePicker from './rec-type-picker';
import RecAlgorithmPicker from './rec-algorithm-picker';
import RecFilters from './rec-filters';

export default {
    name: 'MediaItem',
    components: {
        RecTypePicker,
        RecAlgorithmPicker,
        RecFilters,
        MaterialButton,
    },
    data () {
        return {
            localMediaType: this.mediaType || 'anime',
            localAlgorithm: 'popular',
            showFilters: false,
            showAlgorithm: false,
            seasonFilterEnabled: false,
            seasonYearsToFilter: 1
        };
    },
    computed: {
        ...mapState({
            username: state => state.user.userProfile.data.username,
            mediaType: state => state.recommendations.mediaType,
            mediaChoiceType: state => state.recommendations.mediaChoiceType,
            algorithm: state => state.recommendations.algorithm,
        }),
    },
    methods: {
        ...mapMutations('recommendations', ['setMediaType', 'setAlgorithm']),
        ...mapActions('recommendations', ['getRecommendationsForUser', 'resetRecommendations', 'clearMediaChoices']),
        ...mapActions('genre', ['resetGenreFilter']),
        ...mapActions('filters', ['resetSeasonFilter']),
        async updateMediaType ({ mediaType }) {
            await this.resetRecommendations();
            await this.resetGenreFilter();
            await this.setMediaType({ mediaType });
            this.$emit('onChange');
        },
        async updateRecAlgorithm ({ algorithm }) {
            this.showAlgorithm = false;
            this.localAlgorithm = algorithm;
            if (this.algorithm !== algorithm) {
                this.setAlgorithm({ algorithm });
                this.$emit('onChange');
            }
        },
        toggleGenreFilter () {
            this.showFilters = !this.showFilters;
        },
        updateFilter () {
            if (!this.showFilters) {
                this.$emit('onChange');
            }
        },
        toggleAlgorithmPicker () {
            this.showAlgorithm = !this.showAlgorithm;
        },
        async reset () {
            await this.resetRecommendations();
            await this.resetGenreFilter();
            await this.clearMediaChoices();
            this.resetSeasonFilter();
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.rec-options-panel {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

     @include phone() {
        align-items: center;
    }
}

.button-bar {
    display: flex;
    justify-content: flex-start;

    @include phone() {
        width: 100%;
        padding: 0px 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.rec-type-picker {
    flex-shrink: 0;
    box-sizing: border-box;
    margin-right: 20px;
}

.right-side-options {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.button-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.genre-filter-toggle,
.nerd-only-toggle {
    min-width: 140px;
    margin-right: 20px;

    @include phone() {
        min-width: 120px;
    }
}

.start-over {
    height: 50px;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
    font-size: 18px;
    min-width: 90px;
}

::v-deep .material-button__component {
    height: 50px;

    &.recommend-btn {
        &.anime {
            .material-button {
                font-weight: bold;
                color: $isabelline;
                background-color: $verdegris;
            }
        }

        &.manga {
            .material-button {
                font-weight: bold;
                color: $isabelline;
                background-color: $tufts-blue;
            }
        }
    }
}

</style>
