const initialState = {
    seasonFilterEnabled: false,
    yearsToFilter: 1
};

const mutations = {
    setSeasonFilterEnabled (state, { seasonFilterEnabled }) {
        state.seasonFilterEnabled = seasonFilterEnabled;
    },
    setYearsToFilter (state, { yearsToFilter }) {
        state.yearsToFilter = yearsToFilter;
    }
};

const actions = {
    resetSeasonFilter ({ commit }) {
        commit('setSeasonFilterEnabled', { seasonFilterEnabled: false });
    }
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
