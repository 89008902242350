<template>
    <div class="watch-links">
        <div v-if="item.type === 'anime'">
            <div v-if="!isHentai">
                <a class="external-link kissanime" target="_blank" :href="'http://kissanime.ru/Search/Anime?keyword=' + item.title">
                    <img class="favicon" src="/images/external-service-favicons/kissanime.ico">
                    Watch on KissAnime
                </a>
                <a class="external-link gogoanime" target="_blank" :href="'http://gogoanime.io/search.html?keyword=' + item.title">
                    <img class="favicon" src="/images/external-service-favicons/gogoanime.png">
                    Watch on GoGoAnime
                </a>
            </div>
            <div v-if="isHentai">
                <a class="external-link hentaistream" target="_blank" :href="'http://hentaistream.co/?post_type=anime&s=' + item.title">
                    Watch on Hentaistream
                </a>
                <a class="external-link hentaiplay" target="_blank" :href="'http://hentaiplay.net/?s=' + item.title">
                    Watch on HentaiPlay
                </a>
            </div>
        </div>
        <div v-if="item.type === 'manga'">
            <div v-if="!isHentai">
                <a class="external-link mangahere" target="_blank" :href="'http://www.mangahere.co/search.php?name=' + item.title">
                    <img class="favicon" src="/images/external-service-favicons/mangahere.ico">
                    Read on MangaHere
                </a>
                <a class="external-link batoto" target="_blank" :href="'http://bato.to/search?name=' + item.title">
                    <img class="favicon" src="/images/external-service-favicons/batoto.ico">
                    Read on Batoto
                </a>
            </div>
            <div v-if="isHentai">
                <a class="external-link" target="_blank" :href="'http://hentaifox.com/search/' + item.title">
                    <img class="favicon hentaifox">
                    Read on Hentai Fox
                </a>
                <a class="external-link ehentai" target="_blank"
                    :href="'http://g.e-hentai.org/?f_doujinshi=0&f_manga=1&f_artistcg=0&f_gamecg=0&f_western=0&f_non-h=0&f_imageset=0&f_cosplay=0&f_asianporn=0&f_misc=0&f_search=' + item.title">
                    <img class="favicon">
                    Read on e-hentai
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WatchLinks',
    props: ['item'],
    computed: {
        isHentai () {
            return this.item.genres.find(genre => genre.name.toLowerCase() === 'hentai');
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../../mixins/all.scss";

.watch-links {
    display: block;
    font-size: 14px;

    .favicon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .external-link {
        padding: 5px 0px;
        display: flex;
    }

    a {
        cursor: pointer;
    }
}

</style>
