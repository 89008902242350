<template>
    <div class="loading-modal" v-if="syncing">
        <div class="modal">
            <h2>
                We're syncing your anime and manga lists now...
            </h2>
            <div>
                <div class="blue-text">Heads up!</div>
                <ul>
                    <li>
                        Anirec syncs your MyAnimeList account automatically <b>once a week</b>
                    </li>
                    <li>
                        To manually sync your account, press the "Sync With MyAnimeList" button on this page or on your profile page.
                    </li>
                    <li>
                        If you're a true degenerate, this process might take a few <i>minutes</i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'SyncListModal',
    computed: {
        ...mapState({
            syncing: state => state.user.userListSync.loading,
        }),
    },
};
</script>

<style scoped lang="scss">
@import '../mixins/all.scss';

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: transparentize($isabelline, 0.1);
    z-index: 11;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;

    .modal {
        margin-top: 5%;
        width: 60%;
        background-color: $isabelline;
        padding: 3% 5% 5% 5%;
        box-sizing: border-box;
        font-size: 18px;
        border: 1px solid transparentize($olive-black, 0.8);
    }

    h2 {
        margin-top: 0px;
        border-bottom: 1px solid transparentize($olive-black, 0.8);
        font-style: italic;
        font-size: 28px;
    }

    .blue-text {
        color: $tufts-blue;
        font-size: 24px;
        font-weight: bold;
    }

    ul {
        margin-top: 10px;

        li {
            margin-top: 5px;
        }
    }
}

</style>
