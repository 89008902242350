import axios from 'axios';

const initialState = {
    loading: false,
    error: false,
    quickSearchHistoryEntries: [],
};

const getters = {

};

const actions = {
    loadQuickSearchHistory ({ commit }) {
        commit('loading', true);

        axios.get('/api/last-month-quick-search-history').then((response) => {
            commit('recieved', response.data);
        })
        .catch((err) => {
            commit('failed', err);
        });
    },
};

const mutations = {
    loading (state, isLoading) {
        state.loading = isLoading;
    },
    recieved (state, searchHistoryEntries) {
        state.quickSearchHistoryEntries = searchHistoryEntries;
    },
    failed (state, error) {
        state.error = error;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
