<template>
    <div class="profile-picture" :class="{ small }">
        <img class="picture" v-if="!noProfileImage && userId"
            :src="`https://cdn.myanimelist.net/images/userimages/${userId}.jpg`"
            @error="errorLoadingProfileImage()"/>
        <div class="no-picture" v-if="noProfileImage">
            <span v-if="!small">
                No Profile Picture.
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfilePicture',
    props: ['userId', 'small'],
    data () {
        return {
            noProfileImage: false,
        };
    },
    methods: {
        errorLoadingProfileImage () {
            this.noProfileImage = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.profile-picture {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    max-height: 350px;
    max-width: 225px;
    overflow: hidden;
    flex-shrink: 0;

    &.small {
        max-width: 50px;
        max-height: 50px;
        overflow: hidden;
        border: 1px solid $dark-blue;

        .picture, .no-picture {
            border: none;
        }

        .no-picture {
            width: 50px;
            height: 50px;
        }
    }

    @include phone() {
        max-width: 160px;
    };

    @include tablet() {
        max-width: 160px;
    };

    .picture, .no-picture {
        border: 1px solid $dark-blue;
        box-sizing: border-box;
        width: 100%;
    }

    .no-picture {
        align-items: center;
        background-color: $dark-blue-20;
        color: $dark-blue;
        display: flex;
        font-size: 20px;
        font-weight: bold;
        justify-content: center;
        padding: 20px;
        height: 200px;
        width: 100%;
        text-align: center;
    }
}
</style>
