<template>
    <div class="time-spent-by-score" :class="{ listIsLoading }">
        <div v-if="listIsLoading || mediaList.length">
            <div v-if="listIsLoading" class="loading-text">
                Loading {{ mediaType }} entries...
            </div>
            <div class="stacked-bar-chart">
                <transition-group appear tag="div" class="bars">
                    <div class="time-entry" v-for="(percentageTimeSpent, timeIndex) in timeAsPercentages"
                        :key="timeIndex"
                        :style="{ width: `${percentageTimeSpent}%`}"
                        :class="getClassesForTimeEntry(timeIndex)"
                        @click="toggleShowDetailTableForRating(timeIndex)"
                        v-tooltip="getTimeEntryTooltip(timeByScore[timeIndex], timeIndex)">
                    </div>
                </transition-group>
            </div>
            <div class="explanation-text" v-if="!showDetailTable && !listIsLoading">
                <div class="minimum-rating">
                    {{ minimumScore }}/10
                </div>
                <div v-if="mediaType === 'anime'">
                    <span class="bold">
                        {{ getTimeInDays(totalTimeSpent) }} days ({{ getTimeInHours(totalTimeSpent).toLocaleString() }} hours)
                    </span>
                    spent watching {{mediaType}}
                </div>
                <div v-if="mediaType === 'manga'">
                    <span class="bold">
                        {{ totalTimeSpent.toLocaleString() }} chapters
                    </span>
                    of manga read
                </div>
                <div class="maximum-rating">
                    {{ maximumScore }}/10
                </div>
            </div>
            <media-detail-table v-if="showDetailTable"
                :entry-list="detailTableValues"
                :media-type="mediaType"
                :secondary-column-label="detailTableLabel"
                :rows-to-show="detailRowsToShow"
                secondary-info-key="timeSpentOnEntryLabel"
                @close="closeDetailTable()">
            </media-detail-table>
        </div>
        <div v-if="!mediaList.length && !listIsLoading" class="no-media">
            {{ username }} has not {{ mediaType === 'anime' ? 'watched' : 'read' }} any {{ mediaType }}
        </div>
    </div>

</template>

<script>
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import reduce from 'lodash/reduce';
import map from 'lodash/map';

import MediaDetailTable from '@/components/user-list-view/media-detail-table';

export default {
    name: 'TimeSpentByScore',
    components: { MediaDetailTable },
    props: ['personalMediaList', 'mediaType', 'username'],
    data () {
        return {
            showDetailTable: false,
            detailTableValues: [],
            detailRowsToShow: 10,
        };
    },
    computed: {
        mediaList () {
            return this.personalMediaList.list;
        },
        listIsLoading () {
            return this.personalMediaList.loading;
        },
        timeByScore () {
            const times = {};
            this.mediaList.forEach((listEntry) => {
                if (!listEntry.rawScore || listEntry.rawScore < 1 || listEntry.rawScore > 10) return;

                if (!times[listEntry.rawScore]) times[listEntry.rawScore] = 0;

                if (this.mediaType === 'anime') {
                    times[listEntry.rawScore] += (listEntry.entryDuration) * (listEntry.numberOfEntries);
                } else {
                    times[listEntry.rawScore] += (listEntry.numberOfEntries);
                }
            });

            return times;
        },
        minimumScore () {
            const listWithoutZeroes = this.mediaList.filter(entry => entry.rawScore !== 0);
            if (!listWithoutZeroes.length) return 0;

            return minBy(listWithoutZeroes, entry => entry.rawScore).rawScore;
        },
        maximumScore () {
            if (!this.mediaList.length) return 0;
            return maxBy(this.mediaList, entry => entry.rawScore).rawScore;
        },
        totalTimeSpent () {
            return reduce(this.timeByScore, (sum, time) => sum + time, 0);
        },
        timeAsPercentages () {
            const times = {};
            map(this.timeByScore, (entry, key) => {
                const percentage = ((entry / this.totalTimeSpent) * 100).toFixed(0);
                times[key] = percentage;
            });

            return times;
        },
        detailTableLabel () {
            return this.mediaType === 'anime' ? 'Time spent' : 'Chapters read';
        },
    },
    methods: {
        getTimeInHours (timeInMinutes) {
            return parseInt(timeInMinutes / 60, 10);
        },
        getTimeInDays (timeInMinutes) {
            return parseInt(this.getTimeInHours(timeInMinutes) / 24, 10);
        },
        getTimeEntryTooltip (timeSpent, rating) {
            if (this.mediaType === 'anime') {
                const timeInHours = this.getTimeInHours(timeSpent);
                return `${timeInHours.toLocaleString()} hours spent watching ${rating}/10 anime`;
            }

            return `Read ${timeSpent.toLocaleString()} chapters of ${rating}/10 manga`;
        },
        closeDetailTable () {
            this.showDetailTable = false;
            this.detailRatingToView = 0;
        },
        toggleShowDetailTableForRating (ratingToView) {
            const parsedRating = parseInt(ratingToView, 10);
            if (parsedRating === this.detailRatingToView) {
                this.closeDetailTable();
                return;
            }

            this.showDetailTable = true;
            this.detailRatingToView = parsedRating;
            this.detailTableValues = this.mediaList.filter(entry => entry.rawScore === this.detailRatingToView);

            if (this.mediaType === 'manga') {
                this.detailTableValues = this.detailTableValues.map((entry) => {
                    entry.timeSpentOnEntryLabel = `${entry.percentComplete} chapters`;
                    return entry;
                });
                return;
            }

            this.detailTableValues = this.detailTableValues.map((entry) => {
                entry.timeSpentInMinutes = this.buildTimeSpentInMinutesForEntry(entry);
                entry.timeSpentOnEntryLabel = this.buildTimeSpentOnEntryLabel(entry.timeSpentInMinutes);
                return entry;
            });
            this.detailTableValues = this.detailTableValues.sort((a, b) => b.timeSpentInMinutes - a.timeSpentInMinutes);
        },
        buildTimeSpentInMinutesForEntry (entry) {
            const entryDuration = entry.entryDuration;
            if (!entryDuration) return -1;

            const numberOfEntriesConsumed = entry.percentComplete;
            const timeInMinutes = entryDuration * numberOfEntriesConsumed;
            return timeInMinutes;
        },
        buildTimeSpentOnEntryLabel (timeInMinutes) {
            if (timeInMinutes < 0) return 'Unknown Duration';

            const timeInHours = this.getTimeInHours(timeInMinutes);
            const timeInDays = this.getTimeInDays(timeInMinutes);

            if (timeInDays > 0) {
                return `${timeInDays.toLocaleString()} Day${timeInDays === 1 ? '' : 's'} (${timeInHours.toLocaleString()} hours)`;
            }

            if (timeInHours > 0) {
                return `${timeInHours.toLocaleString()} Hour${timeInHours === 1 ? '' : 's'}`;
            }

            return `${timeInMinutes.toLocaleString()} minutes`;
        },
        getClassesForTimeEntry (timeIndex) {
            const classes = [];

            classes.push(`rating-${timeIndex}`);

            if (this.detailRatingToView === parseInt(timeIndex, 10)) {
                classes.push('selected');
            }

            if (this.showDetailTable) {
                classes.push('viewing-details');
            }

            return classes.join(' ');
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.time-spent-by-score {
    min-height: 65px;
}

.explanation-text {
    font-size: 18px;
    margin-top: 10px;
    opacity: 1;
    text-align: center;
    transition: opacity 200ms linear;
    display: flex;
    justify-content: space-between;

    &.v-enter, &.v-appear {
        opacity: 0;
    }
}

.loading-text {
    font-style: italic;
}

.minimum-rating, .maximum-rating {
    font-weight: bold;
}

.stacked-bar-chart {
    display: flex;
    flex-direction: row;
    height: 30px;
    position: relative;

    .bars {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &.viewing-details {
        opacity: 0.7;
    }

    .time-entry {
        border-right-width: 0px;
        border-left: 1px solid transparentize($isabelline, 0.7);
        cursor: pointer;
        height: 30px;
        position: relative;
        overflow: visible;
        min-width: 10px;

        &:last-child {
            border-right-width: 1px;
        }

        &.v-enter-active {
            transition: width 1.6s cubic-bezier(0, 1, 1, 1);
        }

        &.v-enter {
            width: 0% !important;
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &.viewing-details {
            opacity: 0.5;
        }

        &.selected {
            border-left: 3px solid $isabelline;
            border-right: 3px solid $isabelline;
            opacity: 1;

            // retain correct styling for elements on the ends
            &:first-child {
                border-left: 0px;
            }

            &:last-child {
                border-right: 0px;
            }

            &:after {
                content: " ";
                width: 100%;
                height: 100%;
                position: absolute;
                background: repeating-linear-gradient(
                  45deg,
                  rgba(0,0,0,0),
                  rgba(0,0,0,0) 10px,
                  rgba(0,0,0,0.1) 10px,
                  rgba(0,0,0,0.1) 20px
                );
            }
        }
    }
}

$ratings-color-list: #ea6453, #e56160, #d7597a, #c75196, #b64eb2, #a54fc2, #935ac9, #7f6bc9, #6c7ec9, #5e8ec8;

@for $i from 1 through 10 {
    .rating-#{$i} {
        background-color: nth($ratings-color-list, $i);
    }
}

.media-detail-table {
    margin-top: 10px;
}

.no-media {
    font-style: italic;
    text-align: center;
}

</style>
