<template>
    <div class="condensed-media-list"
        @scroll="infiniteScroll">
        <div class="media-list">
            <condensed-media-entry
                v-for="entry in visibleList" :key="entry.mediaId"
                :media-entry="entry"
                :media-type="mediaType">
            </condensed-media-entry>
            <slot name="placeholder"></slot>
        </div>
        <div class="no-media" v-if="!this.personalMediaList.length">
            <span v-if="emptyMessage">
                {{ emptyMessage }}
            </span>
            <span v-else>
                {{ mediaType }} list is empty.
            </span>
        </div>
    </div>
</template>

<script>
import take from 'lodash/take';

import CondensedMediaEntry from './condensed-media-entry';

export default {
    name: 'CondensedMediaList',
    components: { CondensedMediaEntry },
    props: ['personalMediaList', 'mediaType', 'emptyMessage'],
    data () {
        return {
            sortedList: [],
            numElementsToLoad: 30,
            visibleEntries: 0,
        };
    },
    mounted () {
        this.initializeVisibleList();
    },
    computed: {
        visibleList () {
            return take(this.sortedList, this.visibleEntries);
        },
    },
    methods: {
        infiniteScroll (event) {
            const targetEle = event.target;
            if (targetEle.scrollHeight - targetEle.scrollTop - targetEle.clientHeight < 300) {
                this.visibleEntries += this.numElementsToLoad;
            }
        },
        initializeVisibleList () {
            this.sortedList = this.personalMediaList.slice().sort(this.listSortComparator);
            this.visibleEntries = this.numElementsToLoad;
        },
        listSortComparator (entryA, entryB) {
            const scoreDiff = entryB.rawScore - entryA.rawScore;

            if (scoreDiff) return scoreDiff;

            return entryB.title.toLowerCase()[0] < entryA.title.toLowerCase();
        },
    },
    watch: {
        personalMediaList () {
            this.initializeVisibleList();
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../../mixins/all.scss";

.condensed-media-list {
    overflow-y: auto;
    border: 1px solid transparentize($olive-black, 0.7);
}

.media-list {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    justify-content: space-evenly;
}

.condensed-media-entry {
    box-sizing: border-box;
}

.no-media {
    font-size: 30px;
    display: flex;
    padding-top: 100px;
    color: transparentize($olive-black, 0.6);
    justify-content: center;
    font-weight: bold;
    width: 100%;
}

</style>
