import axios from 'axios';

const initialState = {
    results: null,
    searchTerm: null,
};

const mutations = {
    setSearchTerm (state, searchTerm) {
        state.searchTerm = searchTerm;
    },
    setResults (state, results) {
        state.results = results;
    },
};

const actions = {
    async searchForMedia ({ commit }, { searchTerm, type }) {
        commit('setSearchTerm', searchTerm);
        const searchParams = new URLSearchParams('');
        searchParams.append('title', searchTerm);
        if (type) {
            searchParams.append('type', type);
        }

        const results = await axios.get(`/api/search?${searchParams.toString()}`);

        commit('setResults', results.data);
    },
    clearSearchResults ({ commit }) {
        commit('setSearchTerm', '');
        commit('setResults', null);
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
