<template>
    <div id="search-history">
        <last-month-search-history></last-month-search-history>
    </div>
</template>

<script>
import LastMonthSearchHistory from './last-month-search-history/last-month-search-history';

export default {
    name: 'SearchHistory',
    components: {
        LastMonthSearchHistory,
    },
};
</script>

<style>

</style>
