import Axios from 'axios';

class Interceptor {
    constructor ($progress) {
        this.$progress = $progress;
        this.numberOfRequestsInFlight = 0;
        this.percentComplete = 0;
    }

    increasePercentComplete () {
        let increasePercent = 0;

        if (this.percentComplete >= 0 && this.percentComplete < 25) {
            // Start out between 3 - 6% increments
            increasePercent = ((Math.random() * 3) + 3);
        } else if (this.percentComplete >= 25 && this.percentComplete < 65) {
            // increment between 0 - 3%
            increasePercent = (Math.random() * 3);
        } else if (this.percentComplete >= 65 && this.percentComplete < 9) {
            // increment between 0 - 2%
            increasePercent = (Math.random() * 2);
        } else if (this.percentComplete >= 9 && this.percentComplete < 99) {
            // finally, increment it .5 %
            increasePercent = 0.5;
        }

        this.percentComplete += increasePercent;
        this.$progress.set(this.percentComplete);
    }

    requestSuccess (config) {
        if (this.numberOfRequestsInFlight === 0) {
            this.percentComplete = 0;
            this.$progress.start();
            this.$progress.set(this.percentComplete);
            clearInterval(this.$progress.state.timer);
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.increasePercentComplete();
            }, 250);
        }

        this.numberOfRequestsInFlight += 1;
        return config;
    }

    responseSuccess (config) {
        this.numberOfRequestsInFlight -= 1;
        if (this.numberOfRequestsInFlight === 0) {
            this.$progress.finish();
            clearInterval(this.interval);
        }

        return config;
    }

    responseFailure (error) {
        this.numberOfRequestsInFlight -= 1;
        if (this.numberOfRequestsInFlight === 0) {
            this.$progress.finish();
            clearInterval(this.interval);
        }

        throw error;
    }
}

export default function install ($progress) {
    const instance = new Interceptor($progress);
    Axios.interceptors.request.use(instance.requestSuccess.bind(instance));
    Axios.interceptors.response.use(instance.responseSuccess.bind(instance), instance.responseFailure.bind(instance));
}
