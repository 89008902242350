<template>
    <div class="current-season-entries">
        <div class="title">
            Currently watching in the <span class="season-name">{{currentSeason}} {{currentYear}}</span> season
        </div>
        <div class="currently-watching" v-if="watchedEntries.length">
            <condensed-media-entry
                v-for="entry in watchedEntries"
                :key="entry.id"
                :media-entry="entry"
                :title-subtext="`${entry.percentComplete} episode${entry.percentComplete === 1 ? '' : 's'} watched`">
            </condensed-media-entry>
        </div>
        <div class="no-season-entries" v-if="!watchedEntries.length && !listIsLoading">
            Not watching anything from this season...
        </div>
        <div class="no-season-entries" v-if="listIsLoading">
            Loading entries from this season...
        </div>
    </div>
</template>

<script>
import CondensedMediaEntry from './condensed-media-list/condensed-media-entry';

export default {
    name: 'CurrentSeasonEntries',
    props: ['personalMediaList'],
    components: { CondensedMediaEntry },
    computed: {
        mediaList () {
            return this.personalMediaList.list;
        },
        listIsLoading () {
            return this.personalMediaList.loading;
        },
        currentSeason () {
            const now = new Date();
            const currentMonth = now.getMonth() + 1;

            if (currentMonth <= 3) {
                return 'Winter';
            } else if (currentMonth <= 6) {
                return 'Spring';
            } else if (currentMonth <= 9) {
                return 'Summer';
            }
            return 'Fall';
        },
        currentYear () {
            return (new Date()).getFullYear();
        },
        watchedEntries () {
            const list = this.mediaList
                .filter(entry => entry.season === `${this.currentSeason} ${this.currentYear}`)
                .sort(this.watchedEntriesSortComparator);

            return list;
        },
    },
    methods: {
        watchedEntriesSortComparator (a, b) {
            const scoreDiff = b.rawScore - a.rawScore;
            if (scoreDiff !== 0) return scoreDiff;

            const watchCountDiff = b.percentComplete - a.percentComplete;
            if (watchCountDiff !== 0) return watchCountDiff;

            return b.title.toLowerCase()[0] < a.title.toLowerCase();
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.season-name {
    font-style: italic;
}

.title {
    font-weight: bold;
    font-size: 20px;
}

.no-season-entries {
    height: 40px;
    font-style: italic;
}

.currently-watching {
    border: 1px solid transparentize($olive-black, 0.7);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    max-height: 40vh;
    min-height: 240px;
    overflow-y: auto;
    width: 100%;

    .condensed-media-entry {
        margin: 10px 10px 10px 10px;
    }
}

.no-season-entries {
    display: flex;
    align-items: center;
}
</style>
