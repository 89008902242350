<template>
    <div class="highlight-stats">
        <media-type-stats
            mediaType="anime"
            :personal-media-list="animeList">
        </media-type-stats>
        <media-type-stats
            mediaType="manga"
            :personal-media-list="mangaList">
        </media-type-stats>
    </div>
</template>

<script>
import MediaTypeStats from './media-type-stats/media-type-stats';

export default {
    name: 'HighlightStats',
    components: { MediaTypeStats },
    props: ['mangaList', 'animeList'],
};
</script>

<style scoped lang="scss">
@import "../../../mixins/all.scss";

.highlight-stats {
    display: flex;
    flex-direction: column;

    @include tablet() {
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include phone() {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.media-type-stats {
    margin-bottom: 20px;

    &:first-child {
        margin-right: 20px;
    }
}
</style>
