<template>
    <div class="patch-notes">
        <a class="header bold patch-notes-toggle" @click="showPatchNotes = !showPatchNotes" tabindex="0">
            {{ showPatchNotes ? 'Hide Patch Notes' : 'Show Patch Notes'}}
        </a> (Monday, July 6th, 2020)
        <div class="notes" v-if="showPatchNotes">
            <div class="patch-date bold">
                Monday, July 6th, 2020
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Fixed a parsing error that was causing a subset of user lists to not sync correctly.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Monday, June 1st, 2020
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Added "Plan to watch" and "Plan to read" tabs on the user list. These lists mirror the corresponding lists from MyAnimeList.<br>
                        These items will also no longer show up at the bottom of the anime/manga list tabs.
                    </li>
                    <li>
                        Fixed a data processing error that could result in an incorrect list of "most compatible users" on the user list.
                    </li>
                    <li>
                        A few other small visual bugs were cleaned up on the user list page, such as long media titles incorrectly truncating in some tables.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Friday, February 21st, 2020
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updated backend handling of title parsing based on changes to MyAnimeList
                    </li>
                    <li>
                        Cleaned up several display issue around the mobile experience, particularly with search filters
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, February 2nd, 2020
            </div>
            <div class="indent patch-note">
                WHATS GOOD, EVERYBODY. It's been a while! Lets get into it:<br><br>
                <ul>
                    <li>
                        Season Filters!<br>
                        A keen eye will notice that we've renamed the "Genre Filters" button to just "Filters". You now have the option
                        to limit recommendations to the past 1-5 years. We found more granularity here (ala being able to specify "show me just Fall 2018")
                        wasn't super helpful from a recommendation standpoint, but if you disagree let us know!
                    </li>
                    <li>
                        At some point in the past few months MAL made some tweaks on their end that meant we weren't consistently pulling
                        image urls for anime/manga correctly. This has been fixed on our end - expect everything to self-heal and have images
                        by the end of the week.
                    </li>
                    <li>
                        An embarrasing typo has been correctly, but we're not saying where ;)
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, January 6th, 2019
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Looks like there were some date-related errors cropping up. We're not sure if MAL changed their internal date formats or
                        if this was just a miss on our part, but regardless, list syncing should no longer break on more recent titles.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Saturday, November 24th, 2018
            </div>
            <div class="indent patch-note">
                This is a pretty big one: the entire recommendation workflow has been overhauled to be more streamlined.
                All of the algorithms and backend data remain the same, but we felt the process of actually <i>getting</i> recommendations
                was way too cumbersome and manual. <br /><br />
                <ul>
                    <li>
                        Recommendations will now auto-update when you change any of your preferences.
                    </li>
                    <li>
                        First-time users will see their MAL profile sync automatically.
                    </li>
                    <li>
                        All options around algorithm and genre preferences should be more discoverable.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Tuesday, October 30th, 2018
            </div>
            <div class="indent patch-note">
                YO. What's good everyone. The ability to sync your anime/manga lists from MyAnimeList has finally been
                re-enabled. It's been a pretty discouraging few months with the security shake ups from MAL, but we're back! With that being said, here's a
                quick refresher on what to expect:<br /><br />
                <ul>
                    <li>
                        Anirec is based on large data sets and math. Because we haven't been pulling user lists for the past ~6 months, the data is <i>a little</i> out of date!
                    </li>
                    <li>
                        It should take exactly 1 week (Ending on November 7th 2018) for the data to fully catch up and re-sync everyone's lists
                        (We impose arbitrary rate limiting on how often we pull data from MAL to be kind to their servers :) )
                    </li>
                    <li>
                        Throughout the next week, you may start to see anime recommendations change more often than you'd expect.
                        This is due to user lists naturally updating with ~6 months of new information.
                        Recommendations should stabilize back out after the week is over.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Thursday, February 22nd, 2018
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        There was a brief lapse in our https certificate - apologies for the interruption!
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Friday, November 24th, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        The "most compatible users" section has been re-added to the user list statistics page. There's a little more
                        granularity of information to be had this time around, which can be unlocked by re-syncing your list from MyAnimeList.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, November 19th, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Huge milestone - the entire user list page has been completely rebuilt from the ground
                        up in vue. Rather than being a bare-bones reflection of your list, it should now show
                        some interesting stats about what/when you watch things, and a few other nifty tidbits.
                        We'll be expanding this page over the next few months with additional insights, such as
                        helping you find out at a glance that there are more seasons/entries in a given anime/manga.
                    </li>
                    <li>
                        Some pretty ugly UI oversights have been cleaned up in the recommendations workflow - sorry about that.
                    </li>
                    <li>
                        Syncing a user from myanimelist should now sync both the anime and manga lists simultaneously, rather than
                        you having to click "sync user" for anime and manga separately.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, October 4th, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updated some wording for more clarity around recommendation algorithms and results.
                    </li>
                    <li>
                        Improved responsive results for recommendations lists, particularly for tablets.
                    </li>
                    <li>
                        Began in-place migration from angular 1.6.x -> Vue 2.x. This migration will take some time,
                        but once completed should make the site feel more responsive, and in general cleans up some of the older,
                        nastier code pretty substantially.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, August 13th, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        More options! The previous "Genre Filter" button is now "Advanced Options", and
                        will allow you to choose different recommendation algorithms, in addition to the previous
                        genre filtering functionality. Getting recommendations weighted by genre is a little slower than I'd
                        like right now, but hopefully ~5 seconds isn't too horrible.
                    </li>
                    <li>
                        The UI for recommendations has changed a little bit, in general favoring the existing mobile/tablet
                        layouts over the previous desktop layout. You shouldn't need to hover over things to see what they are
                        anymore, which I'm sure is a relief to many people :P. It should hopefully also make streaming links a
                        little easier to get to.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Tuesday, August 1st, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Recommendation and compatibility computation has changed slightly, to support a larger variety of weights for recommendations.
                    </li>
                    <li>
                        Expect more detailed patch notes in the coming weeks.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Monday, January 16th, 2017
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updated the build pipeline to support older browsers. Anirec
                        should now be fully functional for non-evergreen browsers
                        (even if it doesn't necessarily look fantastic).
                    </li>
                    <li>
                        Minor bugfixes around responsive design at smaller screen sizes.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, December 21st, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Users can now get manga recommendations based on anime they like, and vice versa.
                    </li>
                    <li>
                        Upgraded the build pipeline from <a href="https://www.npmjs.com/package/gulp-usemin">usemin</a> to <a href="https://webpack.js.org/">webpack 2</a>.
                        Users should not notice any difference,
                        but this modernizes the front end code-base a little, and will allow for new features to be built more quickly.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, November 6th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Watch links are now available on all anime
                    </li>
                    <li>
                        Read links are now available on all manga
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Friday, November 4th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        <i>Large</i> performance improvements for generating recommendations. Recommendations should load 5-10x faster than before.
                    </li>
                    <li>
                        The structure of recommendations based on user currated lists from myanimelist has changed. There are now two recommendation categories - "consensus" and "polarized".
                        "Consensus recommendations" should look very familiar, and largely mirror the previous "high compatibility" recommendation category, with some small tweaks for accuracy.
                        "Polarized recommendations" offer a look at anime/manga with mixed opinions - some people loved it, some people hated it. You could be either!
                        These categories should hopefully provide a good mix of "safe" and "risky" (but potentially rewarding) recommendations, depending on what you're looking for.
                        A large amount of refactoring took place to make this happen, and should also allow for more customization in the future.
                        I hope to add more fine-grain user control in the next month or so, to really allow users to focus on the particular metrics that work best for them.
                    </li>
                    <li>
                        Recommendations now paginate, giving users more results (should you want them).
                    </li>
                    <li>
                        I understand these changes are more far-reaching than normal - if you have questions or concerns,
                        please don't hesitate to contact me on twitter @AnirecSupport, or personally <a href="https://myanimelist.net/profile/_flare">via PM on myanimelist.net</a>
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Thursday, October 20th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Adding header to user list page
                    </li>
                    <li>
                        Adding more information to the user list genre graph
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, August 31st, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Adding better error messaging for quick recommendations
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, August 24th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updating back-end to reflect myanimelist URL changes
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Monday, August 8th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Fixing omnibar position for ease of typing (Whoops! Sorry!)
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Thursday, August 4th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Fixed a bug with user sync
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, July 20th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Added contextual information for which categories are being searched by the omnibar
                    </li>
                    <li>
                        Created a twitter account for support, bugs, and feature requests. Feel free to tweet or DM feedback @AnirecSupport!
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, July 17th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        'Sync User List' button now correctly disabled while user list is syncing.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Sunday, July 10th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Errors are now much more substantially tracked on the back end, allowing for easy diagnosis of bugs.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Friday, July 8th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updating 'User Sync' wording to explain the workflow better.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Thursday, July 7th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Fixing errors that would result in some users being unable to get recommendations.
                    </li>
                </ul>
            </div>
            <div class="patch-date bold">
                Wednesday, July 6th, 2016
            </div>
            <div class="indent patch-note">
                <ul>
                    <li>
                        Updated compatibility formulas, making user compatibility calculations (the basis for recommendations) more accurate (hopefully).
                        Compatibility metrics are now reversed - the closer to 0 a compatibility score is, the more compatible you are with that user.
                    </li>
                    <li>
                        Updated recommendation strategies to reflect compatibility changes. Some code refactoring means that, in the future, recommendations can be more customizable.
                        Adjusting the number of users that recommendations are sourced from is one example of this.
                    </li>
                    <li>
                        More data collected around when lists update, for tracking current seasons and trends.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PatchNotes',
    data () {
        return {
            showPatchNotes: false,
        };
    },
};

</script>

<style scoped lang="scss">
@import "../../mixins/all.scss";

.patch-notes {
    display: block;
    padding: 20px 0px;

    .header {
        display: inline-block;
        font-size: 20px;
        margin-top: 0px;
        cursor: pointer;
    }

    .indent {
        padding: 15px 15px;
        margin-left: 20px;
        border-left: 10px solid $olive-black;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .patch-note {
        ul {
            margin: 0px;
        }
    }
}

</style>
