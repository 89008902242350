import { render, staticRenderFns } from "./generic-tabs.vue?vue&type=template&id=5a98bd95&scoped=true"
import script from "./generic-tabs.vue?vue&type=script&lang=js"
export * from "./generic-tabs.vue?vue&type=script&lang=js"
import style0 from "./generic-tabs.vue?vue&type=style&index=0&id=5a98bd95&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a98bd95",
  null
  
)

export default component.exports