<template>
    <div class="media-type-stats">
        <div class="title bold">
            {{ mediaType }} Highlights
        </div>
        <div class="number-of-entries">
            <span class="bold">{{ listIsLoading ? '---' : Number(mediaList.length).toLocaleString() }}</span> {{ mediaType }} attempted
        </div>
        <div class="series-completed">
            <span class="bold">{{ listIsLoading ? '---' : Number(seriesCompleted).toLocaleString() }}</span> {{ mediaType }} completed
        </div>
        <div class="entries-completed">
            <span class="bold">{{ listIsLoading ? '---' : Number(entriesCompleted).toLocaleString() }}</span>
            {{ mediaType === 'anime' ? 'episodes watched' : 'chapters read'}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'HighlightStats',
    props: ['mediaType', 'personalMediaList'],
    computed: {
        mediaList () {
            return this.personalMediaList.list;
        },
        listIsLoading () {
            return this.personalMediaList.loading;
        },
        entriesCompleted () {
            return this.mediaList.reduce((acc, media) => acc + media.percentComplete, 0);
        },
        seriesCompleted () {
            return this.mediaList.reduce((acc, media) => {
                if (media.percentComplete >= media.numberOfEntries) {
                    return acc + 1;
                }

                return acc;
            }, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.media-type-stats {
    font-size: 18px;
}

.title {
    text-transform: capitalize;
    font-size: 25px;
}
</style>
