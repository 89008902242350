<template>
    <div class="anime-manga-tabs">
        <div v-if="!showBothTabs">
            <div class="tab-bar">
                <div class="tabs">
                    <div class="tab-item"
                        @click="showAnimeTab()"
                        :class="{ underline: animeTabSelected }">
                        Anime
                    </div>
                    <div class="tab-item"
                        @click="showMangaTab()"
                        :class="{ underline: mangaTabSelected }">
                        Manga
                    </div>
                </div>
                <div class="tab-title">
                    {{ animeTabSelected ? animeTabTitle : mangaTabTitle }}
                </div>
            </div>
            <div class="tab-content">
                <slot name="anime-tab" v-if="animeTabSelected"></slot>
                <slot name="manga-tab" v-if="mangaTabSelected"></slot>
            </div>
        </div>
        <!-- If tabs can be split past a certain width, render them both -->
        <div v-if="showBothTabs" class="both-tabs">
            <div class="anime-tab">
                <div class="tab-bar">
                    <div class="tabs">
                        <div class="tab-item">
                            Anime
                        </div>
                    </div>
                    <div class="tab-title">
                        {{ animeTabTitle }}
                    </div>
                </div>
                <div class="tab-content">
                    <slot name="anime-tab"></slot>
                </div>
            </div>
            <div class="manga-tab">
                <div class="tab-bar">
                    <div class="tabs">
                        <div class="tab-item">
                            Manga
                        </div>
                    </div>
                    <div class="tab-title">
                        {{ mangaTabTitle }}
                    </div>
                </div>
                <div class="tab-content">
                    <slot name="manga-tab"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AnimeMangaTabs',
    components: { },
    props: ['animeTabTitle', 'mangaTabTitle', 'splitTabsWidth'],
    data () {
        return {
            animeTabSelected: true,
            mangaTabSelected: false,
            showBothTabs: false,
        };
    },
    methods: {
        showAnimeTab () {
            this.animeTabSelected = true;
            this.mangaTabSelected = false;
        },
        showMangaTab () {
            this.animeTabSelected = false;
            this.mangaTabSelected = true;
        },
        checkIfBothTabsCanBeShown () {
            if (!this.splitTabsWidth) return;

            if (window.innerWidth >= this.splitTabsWidth) {
                this.showBothTabs = true;
                return;
            }

            this.showBothTabs = false;
        },

    },
    mounted () {
        this.checkIfBothTabsCanBeShown();
        if (this.splitTabsWidth) {
            window.addEventListener('resize', this.checkIfBothTabsCanBeShown);
        }
    },
    beforeDestroy () {
        if (this.splitTabsWidth) {
            window.removeEventListener('resize', this.checkIfBothTabsCanBeShown);
        }
    },
};

</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.tab-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    user-select: none;
    min-width: 400px;

    .tabs {
        display: flex;
        flex-direction: row;
    }

    .tab-item {
        margin-right: 30px;
        padding-bottom: 4px;
        cursor: pointer;
        font-size: 18px;

        &.underline {
            border-bottom: 3px solid $tufts-blue;
        }
    }

    .tab-title {
        font-size: 18px;
        font-style: italic;
    }
}

.both-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .anime-tab, .manga-tab {
        width: calc(50% - 30px);
    }

    .anime-tab {
        padding-right: 30px;
    }

    .manga-tab {
        padding-left: 30px;
    }

    .tab-item {
        cursor: initial;
        font-weight: bold;
    }
}
</style>
