<template>
    <div id="user-search-view">
        <input class="username-box"
            placeholder="Enter your MyAnimeList username..."
            v-model="username"
            @keypress="enterOnInput($event, username)"/>
        <material-button class="search-btn"
            :disabled="!username"
            :raised="true"
            :ripple="true"
            :large="true"
            @click="goToUserPage(username)">
            Find User
        </material-button>
    </div>
</template>

<script>
import MaterialButton from 'vue-material-button';

export default {
    name: 'UserSearchView',
    components: { MaterialButton },
    data () {
        return {
            username: null
        }
    },
    methods: {
        goToUserPage (username) {
            this.$router.push({ name: 'UserList', params: { username: username.toLowerCase() }});
        },
        enterOnInput (event, username) {
            if(event.which === 13) {
                this.goToUserPage(username);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../mixins/all.scss';

#user-search-view {
    margin-top: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.username-box {
    height: 40px;
    width: 400px;
    margin-right: 20px;
    font-size: 20px;
    padding: 0px 10px;
    box-sizing: border-box;
}

.search-btn {
    & ::v-deep .material-button {
        height: 40px;
        font-size: 20px;
        color: $isabelline;
        cursor: pointer;
        background-color: $verdegris;

        &:hover {
            background-color: darken($verdegris, 5);
        }

        &:disabled,
        &:disabled:hover {
            cursor: not-allowed;
            background-color: transparentize($olive-black, 0.8);
        }
    }
}


</style>
