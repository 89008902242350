<template>
    <div class="profile-overview">
        <profile-header
            :username="username"
            :user-id="userId"
            :show-anirec-link="showAnirecLink">
        </profile-header>
        <highlight-stats
            :manga-list="mangaListObj"
            :anime-list="animeListObj">
        </highlight-stats>
    </div>
</template>

<script>
import ProfileHeader from './profile-header';
import HighlightStats from './highlight-stats/highlight-stats';

export default {
    name: 'ProfileOverview',
    props: ['username', 'userId', 'mangaListObj', 'animeListObj', 'showAnirecLink'],
    components: {
        ProfileHeader,
        HighlightStats,
    },
};
</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.profile-overview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 0;
    width: 265px;
    padding-right: 40px;
    box-sizing: border-box;

    @include tablet() {
        flex-direction: row;
        padding-right: 0px;
        width: 100%;
    };

    @include phone() {
        flex-direction: row;
        padding-right: 0px;
        width: 100%;
        justify-content: center;
    };
}
</style>
